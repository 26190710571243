@import "../../styles/variables";

// TODO: Further refactor / split up of this stylesheet

#street-section-outer {
  position: absolute;
  display: block;
  inset: -$gallery-height 0 -20px; // Bottom inset hides the scrollbar
  overflow-x: scroll;
  overflow-y: hidden;
  touch-action: pan-x;

  /* Prevent overscroll from doing forward/back navigation on some browsers */
  overscroll-behavior-x: contain;

  [dir="rtl"] & {
    direction: ltr;
  }
}

.layout-section-outer {
  margin-inline: auto;
  min-height: 35vh;
  inset: -$gallery-height 0 -20px; // Bottom inset hides the scrollbar
  overflow-x: auto;
  overflow-y: hidden;
  touch-action: pan-x;
  margin-bottom: 8rem;

  /* Prevent overscroll from doing forward/back navigation on some browsers */
  overscroll-behavior-x: contain;

  [dir="rtl"] & {
    direction: ltr;
  }
}

#street-section-inner {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: $canvas-height + 45;
  user-select: none;
}

#street-section-editable,
.street-section-editable {
  position: absolute;
  height: $canvas-height + 80;
  text-align: left;
  touch-action: pan-x;
}

.street-section-layout-phase-name {
  position: relative;
  top: 25px;
  z-index: 1;
  text-align: center;
  font-size: 1.5rem;
}

.street-section-layout-phase-meta {
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 1rem;
}

#street-section-canvas {
  z-index: $z-01-street-section;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  margin-left: $building-space;
  margin-right: $building-space;
  height: $canvas-height + 40;
}

.street-section-building {
  display: block;
  position: absolute;
  top: -105px;
  width: 396px;
  height: $canvas-height + 70px;
  touch-action: pan-x;

  .hover-bk {
    position: absolute;
    z-index: -1;
    inset: -1000px 0 45px;
  }

  &.hover .hover-bk {
    background: $segment-hover-background;
  }

  canvas {
    position: absolute;
    bottom: 0;
  }
}

.street-section-building-left canvas {
  left: 0;
}

.street-segment-building-right canvas {
  right: 0;
}
