// Non-standard prefixed styles
@mixin tap-highlight-color($value: transparent) {
  -webkit-tap-highlight-color: $value;
  -moz-tap-highlight-color: $value;
  tap-highlight-color: $value; // stylelint-disable-line property-no-unknown
}

// Shared style mixins

// Position and layout for error, loading, and blocking shield
@mixin blocking-shield {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  font-size: 1.2rem;
}

@mixin large-message-text {
  font-size: 250%;
  font-weight: 300;
}
