.streetdesignai-logo {
  width: 30vw;
  height: 15vh;
  background-image: url("../images/streetdesignailogo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 4rem;
}

.streetmix-logo {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/logo_main.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.streetmix-logo-horizontal {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/logo_horizontal.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.streetmix-wordmark {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/wordmark_green.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

body.phone #error,
body.phone #loading {
  font-size: 2rem;
}

#svg {
  display: none;
}

#error,
#loading {
  color: $colour-turquoise-700;

  h1 {
    @include large-message-text;

    margin: 0;
  }
}

#error {
  @include blocking-shield;

  z-index: $z-09-error;
  background-color: $colour-turquoise-200;

  // Errors may be displayed on old browsers without flexbox.
  // This forces vertical centering
  display: table;
}

.error-content {
  position: relative;
  text-align: center;
  line-height: 1.6;

  // Errors may be displayed on old browsers without flexbox.
  // This forces vertical centering
  display: table-cell;
  vertical-align: middle;

  a {
    color: black;
    white-space: nowrap;
  }

  button + button {
    margin-left: 1em;
  }

  .error-text {
    margin-top: 4em;
    margin-bottom: 4em;
  }

  .error-help-link {
    margin-top: 2em;
    font-size: 0.85em;

    a,
    a:visited,
    a:active {
      color: $colour-turquoise-700;
    }

    a:hover {
      color: $colour-turquoise-600;
    }
  }
}

#loading {
  @include blocking-shield;

  z-index: $z-09-loading;
  transition: opacity 120ms;
  opacity: 1;
  will-change: opacity;
  background-color: $colour-emerald-200;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .loading-stuck {
    position: absolute;
    bottom: 20px;
    background-color: $alert-background;
    border-bottom: $alert-border;
    padding: 1em;
    border-radius: $border-radius-medium;
    opacity: 0;
    transition: opacity 120ms, transform 150ms ease-out;
    transform: translateY(10px);
  }
}

#loading .streetmix-logo {
  top: -100px;
}

#loading .streetmix-logo,
.error-content .streetmix-logo {
  width: 80%;
  height: 100px;
  max-height: 100px;
  background-position: center center;
}

.error-content .streetmix-logo {
  height: 60px;
  margin: 0 auto;
}

#loading .loading-spinner {
  top: -30px; // Height of spinner element; positions it closer to center

  &::before {
    border-left-color: rgb(255 255 255 / 75%);
    border-right-color: rgb(255 255 255 / 75%);
    border-bottom-color: rgb(255 255 255 / 75%);
  }
}

// --------------------------------------------------------

.debug-hover-polygon {
  position: absolute;
  z-index: $z-07-debug-hover-polygon;
  inset: 0;
  pointer-events: none;

  canvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}
