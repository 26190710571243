.layout-view {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8rem;
  height: 100vh;
  width: 100vw;
  padding-top: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #a9ccdb;
}

.layout-view::-webkit-scrollbar {
  width: 10px;
}

.layout-view::-webkit-scrollbar-thumb {
  background-color: #5bc17d;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

#layout-view-layouts-menu,
#layout-view-phases-menu {
  background-color: #fff;
  position: fixed;
  top: 150px;
  left: 25px;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 1rem;
  list-style-type: none;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1450px) {
    display: none;
  }
}

#layout-view-layouts-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

#layout-view-phases-menu {
  left: unset;
  right: 25px;
}

#layout-view-add-phase-button {
  position: relative;
  z-index: 1;
}
