.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  width: 26px;
  height: 26px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
  background-image: url("layers.760a0456.png");
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  pointer-events: none;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  pointer-events: auto;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  width: 24px;
  height: 24px;
  color: #757575;
  background: none;
  border: none;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

html {
  font-size: 13px;
}

body, button, a.btn, input, select, option {
  font-family: Rubik, sans-serif;
  font-size: 1rem;
}

@font-face {
  font-family: Roadgeek;
  src: url("roadgeek-2005-engschrift.9d228c4b.otf");
}

@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 700;
  src: url("Overpass-Bold.b517aeb2.woff2") format("woff2"), url("Overpass-Bold.ae1d4cdd.woff") format("woff");
}

@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  src: url("Overpass-ExtraBold.a8aa2928.woff2") format("woff2"), url("Overpass-ExtraBold.fecb05cb.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: local(Rubik Regular), local(Rubik-Regular), url("Rubik-Regular.ce320ec5.woff2") format("woff2"), url("Rubik-Regular.6c2fed27.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  src: local(Rubik Medium), local(Rubik-Medium), url("Rubik-Medium.9a09757f.woff2") format("woff2"), url("Rubik-Medium.c07e9d8f.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: local(Rubik Italic), local(Rubik-Italic), url("Rubik-Italic.e0431902.woff2") format("woff2"), url("Rubik-Italic.6f764348.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 200;
  src: url("Manrope-ExtraLight.7bb9a7b6.woff2") format("woff2"), url("Manrope-ExtraLight.ee5bae55.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 300;
  src: url("Manrope-Light.9b3bb370.woff2") format("woff2"), url("Manrope-Light.fb049b75.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  src: url("Manrope-Regular.28fdc4bc.woff2") format("woff2"), url("Manrope-Regular.cfd42453.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  src: url("Manrope-Medium.7f2cfb95.woff2") format("woff2"), url("Manrope-Medium.88eaed30.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  src: url("Manrope-SemiBold.f635b0f2.woff2") format("woff2"), url("Manrope-SemiBold.c854decd.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  src: url("Manrope-Bold.69045a2b.woff2") format("woff2"), url("Manrope-Bold.cf90d9dd.woff") format("woff");
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  src: url("Manrope-ExtraBold.452ccb12.woff2") format("woff2"), url("Manrope-ExtraBold.4c44b192.woff") format("woff");
}

html[lang="ko"] {
  word-break: keep-all;
}

html {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  text-size-adjust: none;
  background: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

body:not(.safari) {
  perspective: 500px;
}

.app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.main-screen {
  z-index: 100;
  flex-grow: 1;
  position: relative;
}

body:not(.safari) .main-screen {
  perspective: 2000px;
}

a, .link-like {
  -webkit-tap-highlight-color: "transparent !important";
  -moz-tap-highlight-color: "transparent !important";
  tap-highlight-color: "transparent !important";
  cursor: pointer;
  color: #61a1bd;
}

a:hover, .link-like:hover {
  color: #366b82;
}

a:active, .link-like:active {
  color: #18303a;
}

.link-like {
  text-decoration: underline;
}

button.link-like {
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  border: 0;
}

input[type="text"], textarea {
  padding: 3px;
}

input[type="text"][disabled], textarea[disabled] {
  background: #dcdcdc4d !important;
  border-color: #dcdcdc !important;
}

hr {
  width: 100%;
  border: 0;
  border-top: 1px solid #9adef2;
  margin-top: 1em;
  margin-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Manrope, Rubik, sans-serif;
}

.streetdesignai-logo {
  width: 30vw;
  height: 15vh;
  background-image: url("streetdesignailogo.4438fcc0.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 4rem;
}

.streetmix-logo {
  width: 100%;
  height: 100%;
  background-image: url("logo_main.6fa3bd71.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.streetmix-logo-horizontal {
  width: 100%;
  height: 100%;
  background-image: url("logo_horizontal.ca9b1120.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.streetmix-wordmark {
  width: 100%;
  height: 100%;
  background-image: url("wordmark_green.5a6505bf.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

body.phone #error, body.phone #loading {
  font-size: 2rem;
}

#svg {
  display: none;
}

#error, #loading {
  color: #154447;
}

#error h1, #loading h1 {
  margin: 0;
  font-size: 250%;
  font-weight: 300;
}

#error {
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: #dcf3f9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 1.2rem;
  display: table;
  position: fixed;
  top: 0;
  left: 0;
}

.error-content {
  text-align: center;
  vertical-align: middle;
  line-height: 1.6;
  display: table-cell;
  position: relative;
}

.error-content a {
  color: #000;
  white-space: nowrap;
}

.error-content button + button {
  margin-left: 1em;
}

.error-content .error-text {
  margin-top: 4em;
  margin-bottom: 4em;
}

.error-content .error-help-link {
  margin-top: 2em;
  font-size: .85em;
}

.error-content .error-help-link a, .error-content .error-help-link a:visited, .error-content .error-help-link a:active {
  color: #154447;
}

.error-content .error-help-link a:hover {
  color: #306c72;
}

#loading {
  width: 100%;
  height: 100%;
  z-index: 900;
  opacity: 1;
  will-change: opacity;
  background-color: #c8edd1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 1.2rem;
  transition: opacity .12s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#loading.hidden {
  opacity: 0;
  pointer-events: none;
}

#loading .loading-stuck {
  opacity: 0;
  background-color: #fff0c0;
  border-bottom: 5px solid #ffd755;
  border-radius: 4px;
  padding: 1em;
  transition: opacity .12s, transform .15s ease-out;
  position: absolute;
  bottom: 20px;
  transform: translateY(10px);
}

#loading .streetmix-logo {
  top: -100px;
}

#loading .streetmix-logo, .error-content .streetmix-logo {
  width: 80%;
  height: 100px;
  max-height: 100px;
  background-position: center;
}

.error-content .streetmix-logo {
  height: 60px;
  margin: 0 auto;
}

#loading .loading-spinner {
  top: -30px;
}

#loading .loading-spinner:before {
  border-bottom-color: #ffffffbf;
  border-left-color: #ffffffbf;
  border-right-color: #ffffffbf;
}

.debug-hover-polygon {
  z-index: 700;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.debug-hover-polygon canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.environment-badge {
  z-index: 300;
  text-transform: uppercase;
  letter-spacing: .5em;
  color: #fff;
  pointer-events: none;
  background-color: gray;
  padding: .25em .5em .25em 1em;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.5;
  display: none;
  position: absolute;
  bottom: -12px;
  left: 14px;
}

.environment-badge.environment-label-development {
  background-color: #c80000;
  display: inline-block;
}

.environment-badge.environment-label-staging, .environment-badge.environment-label-sandbox {
  background-color: green;
  display: inline-block;
}

.environment-badge.environment-label-demo {
  background-color: #1cbdd1;
  display: inline-block;
}

[dir="rtl"] .environment-badge {
  left: auto;
  right: 16px;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"] {
  opacity: 0;
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="top"] {
  transform: translateY(-10px);
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="bottom"] {
  transform: translateY(10px);
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="left"] {
  transform: translateX(-10px);
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="right"] {
  transform: translateX(10px);
}

.tippy-box {
  color: #fff;
  text-align: center;
  max-width: 150px;
  opacity: .85;
  background-color: #2a2b2a;
  border-radius: 4px;
  padding: .5em 1.25em;
  font-weight: bold;
  box-shadow: 0 0 10px #0000001a;
}

.tippy-content {
  padding: 0;
}

.tippy-content p {
  opacity: .75;
  margin: 0;
  font-size: .85em;
  font-weight: normal;
}

.tippy-arrow {
  color: #2a2b2a;
}

.menu-bar .menu-sign-in {
  margin-left: .5em;
  margin-right: .1em;
  padding: .5em 1em;
}

[dir="rtl"] .menu-bar .menu-sign-in {
  margin-left: .1em;
  margin-right: .5em;
}

.menu-bar .menu-upgrade {
  align-items: center;
  margin-left: .75em;
  margin-right: 0;
  padding: .5em 1em;
  display: flex;
}

[dir="rtl"] .menu-bar .menu-upgrade {
  margin-left: 0;
  margin-right: .75em;
}

.menu-bar .menu-upgrade img {
  margin-left: 0;
  margin-right: .5em;
}

[dir="rtl"] .menu-bar .menu-upgrade img {
  margin-left: .5em;
  margin-right: 0;
}

.avatar {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: #fff;
  background-size: 100%;
  border-radius: 50%;
  margin-top: -1px;
  margin-left: 3px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.avatar img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.avatar img:after {
  content: "";
  background-image: url("avatar.c5a8ca24.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: inset 0 0 0 1px #fff;
}

.menu-avatar {
  width: 50px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.menu-avatar .avatar {
  width: 2rem;
  height: 2rem;
  margin: 0;
  position: relative;
  left: -2px;
}

[dir="rtl"] .menu-avatar .avatar {
  left: 1px;
}

.menu-avatar .menu-avatar-badge {
  position: absolute;
  top: 1px;
  left: calc(50% + 4px);
}

[dir="rtl"] .menu-avatar .menu-avatar-badge {
  left: calc(50% + 6px);
}

.menu-avatar-subscriber {
  height: 1em;
}

.menu-bar {
  z-index: 300;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  background: #f7f7f7 padding-box padding-box;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
  box-shadow: 0 0 14px #00000013;
}

.menu-bar:before {
  content: "";
  width: 100%;
  height: 150px;
  background-color: #f7f7f7;
  position: absolute;
  top: -150px;
}

body.read-only .menu-bar {
  display: none;
}

.menu-bar ul {
  color: #28231e;
  margin: 0;
  padding: 3px 1em;
  line-height: 32px;
  list-style: none;
  display: flex;
}

.menu-bar li {
  white-space: nowrap;
  display: inline-block;
}

.menu-bar a {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  color: inherit;
  height: 32px;
  outline: none;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.menu-bar a:active {
  color: #4589a6;
}

.menu-bar a:hover:after {
  content: "";
  width: calc(100% + 8px);
  height: 100%;
  pointer-events: none;
  background-color: #0000000d;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: -4px;
}

.menu-bar button.menu-attached {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  height: 32px;
  background-color: #0000;
  border: 0;
  align-items: center;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.menu-bar button.menu-attached svg {
  margin-left: .25em;
}

[dir="rtl"] .menu-bar button.menu-attached svg {
  margin-left: 0;
  margin-right: .25em;
}

.menu-bar button.menu-attached:active {
  color: #4589a6;
}

.menu-bar button.menu-attached:hover:after {
  content: "";
  width: calc(100% + 8px);
  height: 100%;
  pointer-events: none;
  background-color: #0000000d;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: -4px;
}

.menu-bar-left {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.menu-bar-left li:nth-child(2) button.menu-attached:hover:after {
  width: calc(100% + 4px);
  left: 0;
}

[dir="rtl"] .menu-bar-left li:nth-child(2) button.menu-attached:hover:after {
  left: auto;
  right: 0;
}

.menu-bar-right {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.menu-bar-right li:last-child button.menu-attached:hover:after {
  width: calc(100% + 4px);
}

[dir="rtl"] .menu-bar-right li:last-child button.menu-attached:hover:after {
  left: 0;
}

.menu-bar-title {
  margin-right: .25em;
}

.menu-bar-title h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 100%;
  display: none;
}

.menu-bar-title .menu-bar-logo {
  width: 112px;
  position: relative;
  top: 1px;
}

.menu-external-link {
  margin-left: .25em;
  margin-right: -.2em;
  transform: scale(.8);
}

[dir="rtl"] .menu-external-link {
  margin-left: -.2em;
  margin-right: .25em;
}

.menu-carat-down {
  margin-left: .25em;
  margin-right: -.25em;
}

[dir="rtl"] .menu-carat-down {
  margin-left: -.25em;
  margin-right: .25em;
}

.key {
  color: #404040;
  text-shadow: 0 1px #fff;
  white-space: nowrap;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 .25em;
  padding: .2em .75em;
  font-family: Menlo, monospace;
  font-size: .8em;
  font-weight: bold;
  line-height: 1.8;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px #0003, inset 0 1px #fff;
}

.key-icon {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.menu {
  touch-action: none;
  opacity: 0;
  transform-origin: 50% 0;
  pointer-events: none;
  max-height: calc(100% - 100px);
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  line-height: 1.4;
  transition: transform .1s, opacity .1s;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  transform: rotateX(-90deg);
  box-shadow: 0 0 10px #0000001a;
}

.menu.menu-visible-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: rotateX(0);
}

.menu.menu-visible-exit {
  opacity: 0;
  transform: rotateX(-90deg);
}

.menu > a {
  color: #000;
  border-bottom: 1px solid #dcf3f9;
  padding: .8em 2.5em;
  text-decoration: none;
  display: block;
  position: relative;
}

.menu > a:last-child {
  border-bottom: 0;
}

.menu > a:hover {
  background-color: #ebf9fc;
}

.menu > a:active {
  background-color: #e3f5fa;
}

.menu input {
  -webkit-appearance: none;
  appearance: none;
  resize: none;
  background: #effafd;
  border: 1px solid #dcf3f9;
}

.menu .menu-item-icon {
  width: 16px;
  height: 16px;
  color: #354377;
  position: absolute;
  top: .8em;
  left: .8em;
}

[dir="rtl"] .menu .menu-item-icon {
  left: auto;
  right: .8em;
}

.menu .menu-item-icon-radix {
  width: 15px;
  height: 15px;
  color: #354377;
  position: absolute;
  top: .9em;
  left: .8em;
}

[dir="rtl"] .menu .menu-item-icon-radix {
  left: auto;
  right: .8em;
}

.menu .menu-item-external-link {
  vertical-align: text-top;
  color: #7b8bad;
  margin-left: .25em;
}

body.safari .menu {
  transform: translateY(20px)translateZ(100px);
}

body.safari .menu.menu-visible-enter-done {
  transform: translateY(3px)rotateX(0)translateZ(100px);
}

.menu-item-group {
  border-top: 1px solid #dcf3f9;
  border-bottom: 1px solid #dcf3f9;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-item-group:first-child {
  border-top: 0;
}

.menu-item-group:last-child {
  border-bottom: 0;
}

.menu-item-group + .menu-item-group {
  border-top: 0;
}

.menu-item {
  cursor: pointer;
  padding: .5em 2.5em;
  display: block;
  position: relative;
}

.menu-item:hover {
  background-color: #ebf9fc;
}

.menu-item .fa-check {
  width: 12px;
  height: 12px;
  color: #1c99ce;
  margin-top: -1px;
  display: none;
  left: 1.25em;
}

[dir="rtl"] .menu-item .fa-check {
  left: auto;
  right: 1.25em;
}

.menu-item.menu-item-selected .fa-check {
  display: inline-block;
}

.menu-item .loading-spinner {
  position: absolute;
  top: 8px;
  left: 16px;
}

[dir="rtl"] .menu-item .loading-spinner {
  left: auto;
  right: 16px;
}

.menu-item-subtext {
  color: gray;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: .8em;
  line-height: 8px;
  display: block;
}

.menu-item-icon.fa-facebook, .menu-item-icon.fa-square-facebook {
  color: #1877f2;
}

.menu-item-icon.fa-twitter {
  color: #4a99e9;
}

.menu-item-icon.fa-discord {
  color: #5865f2;
}

.menu-item-icon.fa-github {
  color: #171515;
}

.menu-item-icon.fa-mastodon {
  color: #6364ff;
}

.help-menu-shortcuts {
  max-width: 400px;
  padding: .8em 1em 1em 2.5em;
  position: relative;
}

[dir="rtl"] .help-menu-shortcuts {
  padding-left: 1em;
  padding-right: 2.5em;
}

.help-menu-shortcuts p:first-child {
  margin-top: 0;
}

.help-menu-shortcuts table {
  border-collapse: collapse;
  margin-top: .5em;
}

.help-menu-shortcuts table td {
  vertical-align: top;
  padding: .25em;
}

.help-menu-shortcuts table td:first-child {
  min-width: 60px;
  padding-left: 0;
}

[dir="rtl"] .help-menu-shortcuts table td:first-child {
  padding-left: .25em;
  padding-right: 0;
}

.identity-menu {
  white-space: nowrap;
}

.identity-section {
  min-width: 150px;
  max-width: 300px;
  border-bottom: 1px solid #dcf3f9;
  padding: .8em;
}

.identity-avatar-name {
  flex-direction: row;
  display: flex;
}

.identity-avatar-name-left {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  display: flex;
}

[dir="rtl"] .identity-avatar-name-left {
  margin-left: .5em;
  margin-right: 0;
}

.identity-avatar-name-left .avatar {
  width: 36px;
  height: 36px;
  margin: 0;
}

.identity-avatar-name-right {
  white-space: normal;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
  display: flex;
}

.identity-avatar-display-name {
  color: #154447;
  font-weight: bold;
}

.identity-roles {
  margin-top: .5em;
  margin-bottom: -.5em;
}

.identity-roles ul {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
}

.identity-roles ul li {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: .35em;
  margin-right: .35em;
  padding: .3em .6em;
  font-size: .9em;
  font-weight: 500;
  display: inline-flex;
}

[dir="rtl"] .identity-roles ul li {
  margin-left: .35em;
  margin-right: 0;
}

.identity-roles .role-badge-generic {
  color: #354377;
  background-color: #e6ebf4;
}

.identity-roles .role-badge-subscriber {
  color: #154447;
  background-color: #dcf3f9;
}

.identity-roles .role-badge-subscriber img {
  height: 1em;
  margin-right: .35em;
}

[dir="rtl"] .identity-roles .role-badge-subscriber img {
  margin-left: .35em;
  margin-right: auto;
}

.identity-roles .role-badge-admin {
  color: #554100;
  background-color: #ffd755;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  position: relative;
}

.loading-spinner:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 3px solid #dcf3f9;
  border-top-color: #52a2af;
  border-radius: 50%;
  animation: .75s linear infinite spinner;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-spinner-small {
  width: 13px;
  height: 13px;
}

.loading-spinner-small:before {
  border-width: 2px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.btn, a.btn {
  -webkit-tap-highlight-color: "transparent !important";
  -moz-tap-highlight-color: "transparent !important";
  tap-highlight-color: "transparent !important";
  color: #182549;
  text-align: center;
  cursor: pointer;
  background-color: #c8ecf6;
  border: 0;
  border-radius: 4px;
  outline: none;
  padding: .75em 2em;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.btn:not([disabled]) .svg-inline--fa, a.btn:not([disabled]) .svg-inline--fa {
  color: #182549;
}

.btn:hover, a.btn:hover, .btn:active, a.btn:active {
  background-color: #b4e5f2;
}

.btn[disabled], a.btn[disabled] {
  cursor: auto;
  color: #999 !important;
  background-color: #dcdcdc4d !important;
}

.btn[disabled] .svg-inline--fa, a.btn[disabled] .svg-inline--fa {
  color: #999 !important;
}

.btn[disabled]:hover, a.btn[disabled]:hover {
  background-color: #dcdcdc4d !important;
}

.btn.btn-primary, a.btn.btn-primary {
  color: #fff;
  background-color: #5bc17d;
}

.btn.btn-primary:hover, a.btn.btn-primary:hover {
  background-color: #4bb571;
}

.btn.btn-secondary, a.btn.btn-secondary {
  color: #fff;
  background-color: #52a2af;
}

.btn.btn-secondary:hover, a.btn.btn-secondary:hover {
  background-color: #4a95a0;
}

.btn.btn-tertiary, a.btn.btn-tertiary {
  color: #306c72;
  background-color: #0000;
}

.btn.btn-tertiary:not(:disabled), a.btn.btn-tertiary:not(:disabled) {
  border: 1px solid #89c1ce;
}

.btn.btn-tertiary:hover, a.btn.btn-tertiary:hover {
  color: #154447;
  background-color: #ebf9fc;
}

.share-menu {
  min-width: 350px;
}

.share-via-link-container {
  width: 350px;
  border-bottom: 1px solid #dcf3f9;
  padding: .8em 1em .8em 2.5em;
  position: relative;
}

[dir="rtl"] .share-via-link-container {
  padding: .8em 2.5em .8em 1em;
}

.share-via-link-form {
  flex-direction: row;
  margin-top: .5em;
  display: flex;
}

.share-via-link-form input {
  flex-grow: 1;
}

.share-via-link-form button {
  padding: 4px 10px;
}

.share-via-link-form button svg {
  width: 13px;
}

.share-via-link {
  outline: none;
  margin-right: .5em;
  line-height: 18px;
}

[dir="rtl"] .share-via-link {
  margin-left: .5em;
  margin-right: auto;
}

.share-sign-in-promo {
  width: 350px;
  text-align: center;
  background-color: #fff0c0;
  border-bottom: 2px solid #ffd755;
  padding: 1em 2.5em;
}

.share-sign-in-promo a {
  color: inherit;
  text-decoration: underline;
}

.menus-container {
  pointer-events: none;
  z-index: 775;
  transform-style: preserve-3d;
  position: absolute;
  inset: 0 30px;
}

.phases-menu {
  min-width: 450px;
  padding: 1rem;
}

.phases-menu h1 {
  color: #121212;
}

.phases-menu .phases-menu-item {
  min-height: 2rem;
  display: flex;
}

.phases-menu .phases-menu-item .phases-menu-item-icons {
  place-items: center;
  display: flex;
}

.phases-menu .phases-menu-item .phases-menu-item-icons .phases-menu-move-item, .phases-menu .phases-menu-item .phases-menu-item-icons .phases-menu-delete-item, .phases-menu .phases-menu-item .phases-menu-item-icons .phases-menu-edit-item, .phases-menu .phases-menu-item .phases-menu-item-icons .phases-menu-copy-item {
  width: 16px;
  cursor: pointer;
  margin-inline: 5px;
  display: inline-block;
}

.phases-menu .phases-menu-item .phases-menu-item-text {
  cursor: pointer;
  text-align: right;
  flex-grow: 1;
  margin-left: 1rem;
  padding: .5rem 0;
}

.layouts-menu {
  min-width: 450px;
  padding: 1rem;
}

.layouts-menu h1 {
  color: #121212;
}

.layouts-menu .layouts-menu-item {
  min-height: 2rem;
  display: flex;
}

.layouts-menu .layouts-menu-item .layouts-menu-item-icons {
  place-items: center;
  display: flex;
}

.layouts-menu .layouts-menu-item .layouts-menu-item-icons .layouts-menu-move-item, .layouts-menu .layouts-menu-item .layouts-menu-item-icons .layouts-menu-delete-item, .layouts-menu .layouts-menu-item .layouts-menu-item-icons .layouts-menu-edit-item {
  width: 16px;
  cursor: pointer;
  margin-inline: 5px;
  display: inline-block;
}

.layouts-menu .layouts-menu-item .layouts-menu-item-text {
  cursor: pointer;
  text-align: right;
  flex-grow: 1;
  margin-left: 1rem;
  padding: .5rem 0;
}

body.windows .street-name-text {
  line-height: 38px;
}

.street-name {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: #fff;
  padding: 5px;
  display: inline-block;
}

.street-name-text {
  height: 75px;
  letter-spacing: -.025em;
  text-align: left;
  text-overflow: ellipsis;
  border: 5px solid #000;
  padding: 17px 30px 8px;
  font-family: Overpass, Roadgeek, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 42px;
  overflow: hidden;
}

.street-name-text [dir="rtl"] {
  font-size: 45px;
}

.gallery .streets .street-name {
  max-width: 95%;
  padding: 2px;
  position: relative;
  top: -7px;
}

.gallery .streets .street-name > div, .gallery .streets .street-name > span {
  height: 25px;
  letter-spacing: -.025em;
  border: 2px solid #000;
  padding: 6px 10px 3px;
  font-size: 16px;
  line-height: 14px;
}

.gallery .streets .street-name > div [dir="rtl"], .gallery .streets .street-name > span [dir="rtl"] {
  font-size: 15px;
}

body.segment-move-dragging .street-nameplate-container .street-name {
  pointer-events: none;
}

.street-nameplate-container .street-name {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  max-width: 100%;
  pointer-events: auto;
  position: relative;
}

.street-name-hover-prompt {
  color: #000;
  cursor: pointer;
  white-space: normal;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
  position: absolute;
  inset: 0;
}

.street-width {
  align-items: center;
  display: inline-flex;
}

.street-width.street-width-editable {
  cursor: pointer;
  text-decoration: underline;
}

.street-width.street-width-editable:hover, .street-width.street-width-editable:hover * {
  color: #4589a6;
}

.street-width.street-width-editable svg {
  transform: scale(.85);
}

.street-width-over {
  color: #dc1414;
  margin-left: .25em;
}

[dir="rtl"] .street-width-over {
  margin-left: 0;
  margin-right: .25em;
}

.street-width-under {
  color: #606060;
  margin-left: .25em;
  transition: color .5s;
}

[dir="rtl"] .street-width-under {
  margin-left: 0;
  margin-right: .25em;
}

body.dark-environs-invert-ui .street-width-under {
  color: #a0a0a0;
}

.street-width-select {
  width: 150px;
}

.street-metadata {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: .75em;
  font-size: .95rem;
  transition: color .5s;
  display: flex;
}

.street-metadata a {
  color: inherit;
}

.street-metadata > span {
  white-space: nowrap;
  align-items: center;
  margin-bottom: .25em;
  display: flex;
}

.street-metadata > span:not(:first-child) {
  margin-left: 1em;
}

.street-metadata span > svg:first-child {
  width: 16px;
  height: 16px;
  margin-right: .3em;
  transform: scale(1);
}

body:not(.read-only) .street-metadata-author a:hover {
  color: #4589a6;
}

body.read-only .street-metadata-author a {
  cursor: default;
  text-decoration: none;
}

.street-metadata-author .avatar {
  margin: 0 .5em;
}

.street-metadata-date time {
  margin: 0 .25em;
}

.street-metadata-width > span > svg:first-child {
  width: 15px;
  height: 15px;
}

.street-metadata-analytics a, .street-metadata-map a {
  text-decoration: underline;
}

body.dark-environs-invert-ui .street-metadata {
  color: #fff;
}

body.dark-environs-invert-ui .street-metadata-author:not(:empty):before, body.dark-environs-invert-ui .street-metadata-date:not(:empty):before, body.dark-environs-invert-ui .street-metadata-map:not(:empty):before {
  color: #b0b0b0;
}

.street-metadata-phases {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  max-width: 580px;
  pointer-events: auto;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: auto;
  padding-top: .75em;
  display: flex;
}

.street-metadata-phases div {
  min-width: calc(25% - 1rem);
  max-width: calc(25% - 1rem);
  color: #fff;
  cursor: pointer;
  background-color: #32323266;
  flex: 1 0 calc(25% - 1rem);
  justify-content: center;
  align-items: center;
  padding: .25em 1.25em;
  display: flex;
}

.street-metadata-phases div.selected {
  background-color: #323232cc;
}

.street-metadata-phases div.changed {
  animation: .5s ease-in-out invert;
}

@keyframes invert {
  0% {
    filter: invert(0);
  }

  50% {
    filter: invert();
  }

  100% {
    filter: invert(0);
  }
}

.street-nameplate-container {
  z-index: 300;
  text-align: center;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 150px;
  right: 150px;
}

.street-nameplate-container.move-down-for-menu {
  transform: translateY(20px);
}

.street-nameplate-container.no-movement {
  transition: none !important;
}

.street-nameplate-container.hidden {
  display: none;
}

body.read-only .street-nameplate-container {
  transform: translateY(0);
}

.info-bubble-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff0;
  border-radius: 3px;
  align-items: center;
  margin-top: .15em;
  margin-bottom: .15em;
  margin-left: -.25em;
  padding-left: .25em;
  padding-right: .25em;
  display: flex;
  overflow: hidden;
}

.info-bubble-label-editable .info-bubble-label-editable-icon {
  opacity: 0;
  margin-top: -.2em;
  margin-left: .25em;
  transition: opacity .12s ease-in;
  transform: translateZ(0);
}

.info-bubble-label-editable:hover {
  cursor: text;
  background-color: #ffffff20;
  transition: background-color .12s ease-in;
}

.info-bubble-label-editable:hover .info-bubble-label-editable-icon {
  opacity: .85;
}

.info-bubble-triangle {
  height: 24px;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.info-bubble-triangle:before {
  content: "";
  width: 24px;
  height: 24px;
  pointer-events: none;
  background: #fff;
  margin-left: -12px;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: rotateZ(45deg);
  box-shadow: 0 0 14px #00000013;
}

.info-bubble-triangle-highlight:before {
  background: #e6f0f5;
}

.info-bubble-remove {
  margin-top: 1px;
  margin-left: 1em;
  padding: 0 .75em;
  font-size: 11px;
  width: auto !important;
}

.info-bubble-remove .remove-icon {
  width: 14px;
  height: 14px;
  margin-right: .5em;
}

[dir="rtl"] .info-bubble-remove .remove-icon {
  margin-left: .5em;
  margin-right: 0;
}

.up-down-input-element {
  width: 50px;
  text-align: center;
  vertical-align: bottom;
  height: 30px;
  background: #effafd;
  border: 1px solid #dcf3f9;
  border-left: 0;
  border-right: 0;
  outline: none;
  font-family: Rubik, sans-serif;
  line-height: 22px;
}

.up-down-input-decrement {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.up-down-input-increment {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.up-down-input button svg {
  color: #182549 !important;
}

.building-height .up-down-input {
  flex-direction: column-reverse;
  align-items: center;
  display: flex;
}

.building-height .up-down-input .up-down-input-element {
  width: 100px;
  border-left: 1px solid #dcf3f9;
  border-right: 1px solid #dcf3f9;
  margin-top: 5px;
  margin-bottom: 5px;
}

.building-height .up-down-input .up-down-input-decrement, .building-height .up-down-input .up-down-input-increment {
  border-radius: 4px;
}

.description-prompt, .description-close {
  white-space: normal;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  color: #85b7cc;
  cursor: pointer;
  border-top: 1px solid #cde1ea;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 1em 2em;
  overflow: hidden;
}

.description-prompt:hover, .description-close:hover {
  color: #4589a6;
  background: #e6f0f5;
}

.description-close {
  pointer-events: none;
}

.description-canvas {
  pointer-events: none;
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: -50px;
  left: -50px;
  right: -50px;
  box-shadow: 0 0 14px #00000013;
}

.description {
  height: 100%;
  flex-direction: column;
  font-weight: 300;
  line-height: 1.4;
  display: flex;
  overflow: hidden;
}

.description p {
  margin: 1em 0;
}

.description p:first-child {
  margin-top: 0;
}

.description footer {
  color: #a0a0a0;
  margin: 1em 0;
  font-size: .8em;
}

.description img {
  width: 100%;
  display: block;
}

.description-content {
  touch-action: pan-y;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
}

.description-text {
  padding: 1em;
  font-size: 16px;
}

.description-text h1 {
  margin-top: 0;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.25;
}

.info-bubble {
  min-width: 275px;
  opacity: 0;
  perspective: 1200px;
  z-index: 600;
  pointer-events: none;
  touch-action: none;
  background: #fff;
  border-radius: 4px;
  transition: transform .1s, margin-top .15s, height .15s, opacity .15s;
  position: absolute;
  transform: rotateX(-80deg);
  box-shadow: 0 0 14px #00000013;
}

.info-bubble.visible {
  opacity: 1;
  pointer-events: auto;
  transform: rotateY(0);
}

.info-bubble header {
  height: 40px;
  color: #fff;
  background: #85b7cc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 10px;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 32px;
  display: flex;
  position: relative;
}

.info-bubble .icon {
  pointer-events: none;
  width: 100%;
  height: 100%;
  fill: #231f20;
  padding: 2px 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.info-bubble .svg-inline--fa {
  vertical-align: -.1em;
  height: 11px;
}

.info-bubble .variants {
  text-align: left;
  min-height: 30px;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 20px;
}

.info-bubble .variants button {
  margin-bottom: 2px;
  margin-right: 3px;
}

.info-bubble .variants hr {
  opacity: 0;
  height: 5px;
  margin: 0;
  padding: 0;
  display: block;
}

.info-bubble .variants button[disabled] .icon {
  opacity: .4;
}

.info-bubble .variants .fa-lock {
  color: #894528;
  position: absolute;
  top: -2px;
  right: -2px;
}

[dir="rtl"] .info-bubble .variants .fa-lock {
  left: -2px;
  right: auto;
}

[dir="rtl"] .info-bubble .variants {
  text-align: right;
  direction: ltr;
  margin-left: 20px;
  margin-right: 0;
}

.info-bubble .non-variant {
  text-align: right;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
}

.info-bubble .non-variant:only-child {
  width: 100%;
  text-align: center;
}

.info-bubble .non-variant.building-height {
  height: auto;
  white-space: normal;
  width: 115px;
  text-align: center;
  margin-top: -36px;
}

.info-bubble .variants button, .info-bubble .non-variant button {
  white-space: nowrap;
  padding: 0;
  position: relative;
}

.info-bubble .variants button .icon, .info-bubble .non-variant button .icon {
  opacity: .666;
}

.info-bubble .variants button:not([disabled]):active .icon, .info-bubble .non-variant button:not([disabled]):active .icon {
  opacity: .9;
  margin-top: 1px;
}

.info-bubble .variant-selected, .info-bubble .variant-selected:hover {
  opacity: 1;
  background: #f8b890 !important;
}

.info-bubble .variant-selected .icon, .info-bubble .variant-selected:hover .icon {
  opacity: 1 !important;
}

.info-bubble button {
  width: 30px;
  height: 30px;
}

.info-bubble-type-building header {
  border-top-right-radius: 0;
  margin-right: 140px;
}

[dir="rtl"] .info-bubble-type-building header {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  margin-left: 140px;
  margin-right: 0;
}

.info-bubble-type-building header:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 40px solid #85b7cc;
  border-right: 40px solid #0000;
  position: absolute;
  top: 0;
  right: -40px;
}

[dir="rtl"] .info-bubble-type-building header:after {
  border-left: 37px solid #0000;
  border-right: none;
  left: -37px;
  right: auto;
}

.info-bubble-controls {
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.info-bubble-controls .non-variant {
  text-align: center;
  width: auto;
}

.info-bubble-warnings {
  white-space: normal;
  color: #dc1414;
  border-top: 1px solid #cde1ea;
  margin-top: 1px;
  padding: .5em 1.5em .5em 1em;
}

.info-bubble-warnings ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.info-bubble-warnings li {
  text-align: center;
  margin-bottom: .5em;
}

.info-bubble-warning-alert {
  color: #ff8300;
}

.info-bubble-warning-alert img {
  width: 1.6em;
  height: 1.6em;
  vertical-align: bottom;
  position: relative;
  top: 2px;
}

.info-bubble-warning-error img {
  width: 1.6em;
  height: 1.6em;
  vertical-align: bottom;
  position: relative;
  top: 1px;
}

.info-bubble.show-description .variants, .info-bubble.show-description .non-variant, .info-bubble.show-description .warnings, .info-bubble.show-description .description-prompt {
  pointer-events: none;
}

.info-bubble.show-description .description-close, .info-bubble.show-description .description {
  pointer-events: auto;
}

[dir="rtl"] .info-bubble-remove {
  margin-left: 0;
  margin-right: 1em;
}

button.scrollable {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  width: 25px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
  padding: 0;
  position: absolute;
  top: 0;
}

button.scrollable[disabled] {
  opacity: 0;
  pointer-events: none;
}

.segment.segment-in-palette {
  height: 80px;
  width: 60px;
  transform-origin: 50% 75%;
  background-color: #a9ccdbb3;
  margin: 14px 1px 14px 0;
  display: inline-block;
  position: relative;
  top: 0;
  transition: transform 50ms !important;
}

.segment.segment-in-palette > div {
  height: 100%;
  width: 100%;
  text-align: center;
}

.segment.segment-in-palette:not(.segment-disabled):hover {
  z-index: 1;
  background: #a9ccdbf2;
}

.segment.segment-in-palette:not(.segment-disabled):hover .segment-image {
  transform-origin: 50% 75px;
  transform: scale(1.15);
}

.segment.segment-in-palette .segment-image {
  top: 0;
}

.segment.segment-disabled {
  background: #c2c2c2b3;
}

.segment.segment-disabled .segment-image {
  filter: grayscale(.8);
  opacity: .5;
}

.segment.segment-disabled .fa-lock {
  z-index: 2;
  color: #894528;
  position: absolute;
  top: 4px;
  right: 4px;
}

[dir="rtl"] .segment.segment-disabled .fa-lock {
  left: 4px;
  right: auto;
}

.palette-container-outer {
  width: 100%;
  height: 64px;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.palette-container {
  width: 100%;
  min-width: 768px;
  z-index: 200;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  background: #f7f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .palette-container-outer {
    padding-left: 0;
    padding-right: 0;
  }

  .palette-container {
    min-width: 100%;
    border-radius: 0;
    left: 0;
    right: 0;
  }
}

.palette-commands {
  width: 120px;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  display: flex;
}

.palette-commands button {
  height: 40px;
  width: 40px;
  padding: 0;
}

.palette-commands button + button {
  margin-left: .25em;
}

[dir="rtl"] .palette-commands button + button {
  margin-left: 0;
  margin-right: .25em;
}

.palette-commands-right {
  justify-content: flex-end;
}

.palette-items-scrollable-container {
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  position: relative;
}

.palette-items-scrollable-container button.scrollable {
  width: 30px;
  z-index: 2;
  position: relative;
}

.palette-items-scrollable-container button.scrollable.scroll-right {
  right: -20px !important;
}

.palette-items-scrollable-container button.scrollable.scroll-left {
  left: -20px !important;
}

[dir="rtl"] .palette-items-scrollable-container {
  direction: ltr;
}

.palette-items {
  height: 124px;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.palette-items ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.palette-container-outer {
  width: 100%;
  height: 64px;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.palette-container {
  width: 100%;
  min-width: 768px;
  z-index: 200;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  background: #f7f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .palette-container-outer {
    padding-left: 0;
    padding-right: 0;
  }

  .palette-container {
    min-width: 100%;
    border-radius: 0;
    left: 0;
    right: 0;
  }
}

.palette-commands {
  width: 120px;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  display: flex;
}

.palette-commands button {
  height: 40px;
  width: 40px;
  padding: 0;
}

.palette-commands button + button {
  margin-left: .25em;
}

[dir="rtl"] .palette-commands button + button {
  margin-left: 0;
  margin-right: .25em;
}

.palette-commands-right {
  justify-content: flex-end;
}

.palette-trashcan {
  height: 94px;
  opacity: 1;
  z-index: 700;
  color: #6c6c6c;
  pointer-events: none;
  background: #e3dfda;
  border-top: 2px dashed #6c6c6c;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 400;
  transition: transform .15s, opacity .15s;
  display: flex;
  position: absolute;
  bottom: 0;
  left: -50px;
  right: -50px;
  transform: translateY(0);
}

.palette-trashcan[hidden] {
  opacity: 0;
  transform: translateY(150px);
}

.palette-container-outer {
  width: 100%;
  height: 64px;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.palette-container {
  width: 100%;
  min-width: 768px;
  z-index: 200;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  background: #f7f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .palette-container-outer {
    padding-left: 0;
    padding-right: 0;
  }

  .palette-container {
    min-width: 100%;
    border-radius: 0;
    left: 0;
    right: 0;
  }
}

.palette-commands {
  width: 120px;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  display: flex;
}

.palette-commands button {
  height: 40px;
  width: 40px;
  padding: 0;
}

.palette-commands button + button {
  margin-left: .25em;
}

[dir="rtl"] .palette-commands button + button {
  margin-left: 0;
  margin-right: .25em;
}

.palette-commands-right {
  justify-content: flex-end;
}

.team-member {
  text-align: center;
}

.team-member-name {
  margin-top: .5em;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.2;
  display: block;
}

.credits-team-past .team-member-name {
  font-size: 1em;
}

.team-member-name a {
  color: #000;
  text-decoration: none;
}

.team-member-title {
  color: gray;
  overflow-wrap: anywhere;
  margin-top: .25em;
  font-size: .9em;
  line-height: 1.2;
  display: block;
}

.team-member-mugshot {
  max-width: 100%;
  background-image: url("avatar.c5a8ca24.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}

.team-member-mugshot:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.credits-team {
  grid-gap: 1.5em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: row;
  display: grid;
}

.credits-team-past {
  grid-gap: 1.25em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.credits-container {
  margin-top: 1.5em;
  display: flex;
}

.credits-container > div {
  flex-grow: 1;
}

.credits-container h4 {
  color: gray;
  margin-top: 1em;
  margin-bottom: .25em;
}

.credits-container i {
  color: gray;
  font-style: normal;
}

.social-links li {
  margin-right: 1em;
  display: inline-block;
}

.social-links a {
  color: #7b8bad;
}

.social-links a:hover {
  color: #536491;
}

.social-links svg {
  width: 1.5em;
  height: 1.5em;
}

.social-github {
  color: #171515;
}

.social-github:hover {
  color: #151313;
}

.social-discord {
  color: #5865f2;
}

.social-discord:hover {
  color: #3949f0;
}

.social-mastodon {
  color: #6364ff;
}

.social-mastodon:hover {
  color: #563acc;
}

.social-twitter {
  color: #4a99e9;
}

.social-twitter:hover {
  color: #2f8ae6;
}

.social-instagram {
  color: #e1306c;
}

.social-instagram:hover {
  color: #c13584;
}

button.close {
  width: 34px;
  height: 34px;
  -webkit-appearance: none;
  appearance: none;
  color: #52a2af;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 2px;
  right: 2px;
}

[dir="rtl"] button.close {
  left: 2px;
  right: auto;
}

button.close > .octicon {
  transform: scale(.8);
}

button.close:hover {
  color: #306c72;
  background-color: #ffffff80;
}

button.close:active {
  background-color: #ffffffbf;
}

button.close svg, button.close:hover svg {
  color: inherit !important;
}

.dialog-box-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.dialog-box-backdrop {
  z-index: 800;
  touch-action: none;
  opacity: 0;
  background: #a9ccdb80;
  transition: opacity 60ms ease-in-out;
  position: absolute;
  inset: 0;
}

.dialog-box {
  z-index: 900;
  touch-action: none;
  opacity: 0;
  background: #fff;
  border-radius: 6px;
  margin-top: -3%;
  padding: 0;
  line-height: 1.4;
  transition: opacity 80ms ease-in-out, transform 80ms ease-in-out;
  position: relative;
  overflow: hidden;
  transform: scale(.9);
  box-shadow: 0 0 20px #00000013;
}

.dialog-box > div {
  flex-direction: column;
  display: flex;
}

.dialog-box header {
  text-align: center;
  color: #154447;
  background-color: #dcf3f9;
  padding: 1.75rem 1em;
}

.dialog-box h1 {
  margin: 0;
  font-size: 250%;
  font-weight: 300;
}

.dialog-box footer {
  color: #a0a0a0;
  margin-top: 0;
  padding: 1rem 2rem;
  font-size: .85em;
}

.dialog-box footer > p:first-child {
  margin-top: 0;
}

.dialog-box footer a {
  color: inherit;
}

.dialog-box footer a:hover {
  color: #787878;
}

.dialog-box button.close {
  z-index: 900;
}

.dialog-box button.btn, .dialog-box a.btn {
  margin: 1em auto 0;
}

.dialog-transition-enter-done .dialog-box {
  opacity: 1;
  transform: scale(1);
}

.dialog-transition-exit .dialog-box {
  opacity: 0;
  transform: scale(.9);
}

.dialog-transition-enter-done .dialog-box-backdrop {
  opacity: 1;
}

.dialog-transition-exit .dialog-box-backdrop {
  opacity: 0;
}

.dialog-content {
  flex-grow: 1;
  position: relative;
}

.dialog-content:not(.dialog-content-bleed) {
  padding: 1rem 2rem;
}

.dialog-content > p:first-child {
  margin-top: 0;
}

button.dialog-primary-action {
  width: 100%;
  text-align: center;
  color: #306c72;
  min-height: 46px;
  cursor: pointer;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #9adef2;
  border-radius: 0;
  font-weight: normal;
  margin-top: 0 !important;
}

button.dialog-primary-action:hover {
  color: #154447;
  background-color: #ebf9fc;
}

.about-dialog {
  width: 820px;
  min-height: 660px;
  max-height: 75vh;
}

.about-dialog ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.about-dialog header {
  text-align: left;
  background-color: #fff;
  padding: 0;
}

[dir="rtl"] .about-dialog header {
  text-align: right;
}

.about-dialog header h1 {
  display: none;
}

.about-dialog .dialog-content {
  overflow-x: hidden;
  overflow-y: scroll;
}

.about-dialog-logo {
  height: 36px;
  margin: 2.5rem 2rem 2rem;
}

.about-dialog-content {
  margin-bottom: 2em;
  display: flex;
}

.about-dialog-left {
  width: 280px;
  border-right: 1px solid #9adef2;
  flex-basis: 240px;
  margin-right: 40px;
  padding-right: 40px;
  position: fixed;
}

[dir="rtl"] .about-dialog-left {
  border-left: 1px solid #9adef2;
  border-right: 0;
  margin-left: 40px;
  margin-right: 0;
  padding-left: 40px;
  padding-right: 0;
}

.about-dialog-left p:first-child {
  margin-top: 0;
}

.about-dialog-left p {
  margin-top: 1.5em;
}

.about-dialog-right {
  flex: 1;
  margin-bottom: 15px;
  margin-left: 320px;
}

[dir="rtl"] .about-dialog-right {
  margin-left: 0;
  margin-right: 320px;
}

.about-dialog-right > :first-child {
  margin-top: 0;
}

.about-dialog-right > h2 {
  margin-top: 1.5em;
}

.about-dialog-sponsors {
  margin-top: -1em !important;
}

.about-dialog-sponsors img {
  height: 40px;
}

.about-dialog-sponsors li {
  margin-top: 1em;
  margin-right: 1.5em;
  display: inline-block;
}

[dir="rtl"] .about-dialog-sponsors li {
  margin-left: 1.5em;
  margin-right: 0;
}

.checkbox-item {
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

.checkbox-item input {
  opacity: .00001;
  -webkit-appearance: none;
  appearance: none;
  z-index: 2;
  position: absolute;
}

.checkbox-item label {
  cursor: pointer;
  margin-left: 1.3em;
  padding: 0 2px;
  display: inline-block;
  position: relative;
}

.checkbox-item label:before {
  content: "";
  height: 1em;
  width: 1em;
  color: inherit;
  background-color: #0000;
  border: 1px solid #a6b2c8;
  border-radius: 2px;
  transition: background-color 60ms, color 60ms;
  display: block;
  position: absolute;
  top: .25em;
  left: -1.2em;
}

.checkbox-item input:checked ~ label:before {
  background-color: #fff;
  border-color: #a6b2c8;
}

.checkbox-item input:active:not(:disabled) ~ label:before {
  background-color: #52a2af;
  border-color: #52a2af;
}

.checkbox-item input:focus:not(:disabled) ~ label:before, .checkbox-item input:hover:not(:disabled) ~ label:before {
  border: 1px solid #52a2af;
}

.checkbox-item input:focus:checked:not(:disabled) + label:before, .checkbox-item input:hover:checked:not(:disabled) + label:before {
  border-color: #52a2af;
}

.checkbox-item input:disabled + label:before {
  border-color: #a6b2c8;
}

.checkbox-item input ~ svg {
  opacity: 0;
  color: #0000;
  pointer-events: none;
  position: absolute;
  top: .25em;
  left: .1em;
  transform: scale(.75);
}

.checkbox-item input:checked ~ svg {
  color: #52a2af;
  opacity: 1;
}

.checkbox-item input:active ~ svg {
  color: #fff;
}

.checkbox-item input:checked:disabled ~ svg {
  color: #a6b2c8;
}

.checkbox-item input:focus ~ label {
  outline: 1px dotted #52a2af;
}

.checkbox-item input:disabled ~ label {
  color: #a6b2c8;
  cursor: default;
}

.segment-analytics {
  width: 100%;
  white-space: nowrap;
  margin-bottom: .25em;
  position: relative;
}

.capacity-bars {
  height: 50px;
  min-width: 25px;
  margin-left: 25px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.capacity-icon {
  height: 50px;
  width: 50px;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}

[data-color="1"] .capacity-icon {
  background-color: #78d293;
}

[data-color="2"] .capacity-icon {
  background-color: #9adef2;
}

[data-color="3"] .capacity-icon {
  background-color: #f6a06b;
}

[data-color="4"] .capacity-icon {
  background-color: #8f9eba;
}

.capacity-icon-image {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border: 3px solid;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-left: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
}

[data-color="1"] .capacity-icon-image {
  border-color: #5bc17d;
}

[data-color="2"] .capacity-icon-image {
  border-color: #89c1ce;
}

[data-color="3"] .capacity-icon-image {
  border-color: #d88d64;
}

[data-color="4"] .capacity-icon-image {
  border-color: #7b8bad;
}

.capacity-icon-image img {
  width: 100%;
}

.capacity-bar {
  height: 50px;
  border-radius: 0 25px 25px 0;
  position: absolute;
  left: 0;
}

[data-color="1"] .capacity-bar.capacity-bar-average {
  background-color: #78d293;
}

[data-color="2"] .capacity-bar.capacity-bar-average {
  background-color: #9adef2;
}

[data-color="3"] .capacity-bar.capacity-bar-average {
  background-color: #f6a06b;
}

[data-color="4"] .capacity-bar.capacity-bar-average {
  background-color: #8f9eba;
}

[data-color="1"] .capacity-bar.capacity-bar-potential {
  background-color: #d3f1da;
}

[data-color="2"] .capacity-bar.capacity-bar-potential {
  background-color: #e3f5fa;
}

[data-color="3"] .capacity-bar.capacity-bar-potential {
  background-color: #faddcd;
}

[data-color="4"] .capacity-bar.capacity-bar-potential {
  background-color: #dde2ed;
}

.capacity-text {
  vertical-align: top;
  display: inline-block;
}

.capacity-text .capacity-label {
  font-weight: bold;
}

.analytics-dialog {
  width: 820px;
  max-height: 75vh;
  min-height: 500px;
}

.analytics-dialog .dialog-content {
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.analytics-dialog-content {
  flex-grow: 1;
  display: inline;
}

.analytics-dialog-left {
  width: 520px;
  flex-basis: 240px;
  margin-right: 40px;
  padding-right: 40px;
  position: fixed;
}

[dir="rtl"] .analytics-dialog-left {
  border-right: 0;
  margin-left: 40px;
  margin-right: 0;
  padding-left: 40px;
  padding-right: 0;
}

.analytics-dialog-left p:first-child {
  margin-top: 0;
}

.analytics-dialog-left p {
  margin-top: 1.5em;
}

.analytics-dialog-right {
  flex: 1;
  margin-bottom: 15px;
  margin-left: 520px;
}

[dir="rtl"] .analytics-dialog-right {
  margin-left: 0;
  margin-right: 320px;
}

.analytics-dialog-right > :first-child {
  margin-top: 0;
}

.analytics-dialog-right > h2 {
  margin-top: 1.5em;
}

.analytics-dialog-sponsors {
  margin-top: -1em !important;
}

.analytics-dialog-sponsors img {
  height: 40px;
}

.analytics-dialog-sponsors li {
  margin-top: 1em;
  margin-right: 1.5em;
  display: inline-block;
}

[dir="rtl"] .analytics-dialog-sponsors li {
  margin-left: 1.5em;
  margin-right: 0;
}

.dialog-actions, .analytics-dialog footer {
  text-align: center;
}

.analytics-dialog footer a {
  white-space: nowrap;
}

.analytics-settings-notice {
  width: max-content;
  color: #562211;
  background-color: #f9eee8;
  border-radius: 999em;
  margin: .5em auto 0;
  padding: .5em 1em;
}

.analytics-settings-notice svg {
  color: #b56d48;
  margin-right: .75em;
}

.feature-flag-dialog {
  width: 600px;
}

.feature-flag-dialog ul {
  column-count: 2;
  column-gap: 2em;
  padding: 0;
  list-style-type: none;
}

.feature-flag-dialog ul li {
  margin: 0 0 .25em;
}

.feature-flag-dialog p {
  text-align: center;
}

.feature-flag-label-modified {
  font-weight: bold;
}

.geotag-dialog {
  width: 90vw;
  height: 80vh;
  max-height: 960px;
  max-width: 1400px;
}

.geotag-dialog .close {
  z-index: 1000;
  text-shadow: 0 1px #fff;
}

.geotag-error-banner {
  text-align: center;
  z-index: 500;
  width: calc(100% - 200px);
  background-color: #fff0c0;
  border-bottom: 5px solid #ffd755;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0 100px;
  padding: .75em 2.5em;
  position: absolute;
  top: 0;
}

.geotag-input-container {
  width: 100%;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  top: 10px;
}

.geotag-input-form {
  width: 30vw;
  min-width: 220px;
  pointer-events: auto;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 0 4px #ffffffd9;
}

.geotag-input-clear {
  cursor: pointer;
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
}

.geotag-input {
  width: 100%;
  resize: none;
  font-size: inherit;
  background-color: #effafd;
  border: 1px solid #dcf3f9;
  line-height: 2;
  padding: 3px 24px 3px 6px !important;
}

.geotag-suggestions-container {
  background-color: #fff;
  border: 1px solid #dcf3f9;
  border-top: 0;
  font-family: Rubik, sans-serif;
  list-style-type: none;
}

.geotag-suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.geotag-suggestion {
  cursor: pointer;
  padding: 6px;
}

.geotag-suggestion[aria-selected="true"] {
  background-color: #effafd;
}

.leaflet-container {
  height: 100%;
  font-family: Rubik, sans-serif;
  font-size: inherit;
  background-color: #f7f7f7;
}

.leaflet-container .leaflet-control-attribution {
  text-shadow: 0 1px #fff;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  margin: .25em;
}

.leaflet-container .leaflet-control-attribution a {
  pointer-events: auto;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 3px 10px #00000026;
}

.leaflet-popup-tip-container {
  height: 24px;
  bottom: -24px;
}

.leaflet-popup-tip {
  width: 24px;
  height: 24px;
  box-shadow: none;
  background: #fff;
  margin-left: -12px;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: rotateZ(45deg);
}

.leaflet-popup-content {
  text-align: center;
  margin: 0;
  padding: 1em;
}

.leaflet-control-zoom {
  background-color: #fff;
  padding: 2px;
}

.leaflet-left .leaflet-control, .leaflet-top .leaflet-control {
  margin-top: 12px;
}

.leaflet-touch .leaflet-bar, .leaflet-bar {
  border: 0;
  box-shadow: 0 0 10px #0000001a;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: inherit;
}

.leaflet-bar a, .leaflet-bar a:hover {
  font-size: inherit;
  width: 30px;
  height: 30px;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  line-height: 30px;
}

.leaflet-bar a:first-child, .leaflet-touch .leaflet-bar a:first-child {
  border-bottom: 1px solid #e7e7e7;
}

.popover-trigger {
  vertical-align: bottom;
  width: 23px;
  height: 23px;
  color: #536491;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 0 .35em;
  padding: 0;
  display: inline-flex;
}

.popover-trigger:hover {
  background-color: #e6ebf4;
}

.popover-content {
  max-width: 200px;
  color: #414141;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  padding: .8em 1.2em;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-weight: normal;
  overflow: hidden;
  box-shadow: 0 5px 20px #18254926, 0 10px 20px -5px #1825490d;
}

.popover-content:focus {
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633, 0 0 0 3px #52a2af33;
}

.popover-arrow {
  fill: #fff;
}

.slider-root {
  width: 100%;
  height: 19px;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  align-items: center;
  margin: .25em 0;
  display: flex;
  position: relative;
}

.slider-track {
  height: 7px;
  background-color: #e3f5fa;
  border-radius: 999em;
  flex-grow: 1;
  position: relative;
}

.slider-track[data-disabled] {
  background-color: #e6ebf4;
}

.slider-range {
  height: 7px;
  background-color: #9adef2;
  border-radius: 999em;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-range[data-disabled] {
  background-color: #d4dbe8;
}

.slider-thumb {
  width: 19px;
  height: 19px;
  cursor: pointer;
  background-color: #52a2af;
  border-radius: 999em;
  margin-top: -9px;
  margin-left: -9px;
  display: block;
  position: absolute;
  box-shadow: 0 2px 10px #00000024;
}

.slider-thumb[data-disabled] {
  background-color: #a6b2c8;
}

.slider-thumb:hover:not([data-disabled]) {
  background: #71b4c4;
}

.slider-thumb:focus:not([data-disabled]) {
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #fff, 0 0 0 3px #52a2af;
}

.streetmix-plus-prompt button {
  width: 100%;
  color: #306c72;
  background-color: #0000;
  border: 1px solid #89c1ce;
}

.streetmix-plus-locked-banner {
  color: #894528;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: .5em 0;
  font-size: .9em;
  font-weight: bold;
  display: flex;
}

.streetmix-plus-locked-banner .fa-lock {
  margin: .2em .5em;
  font-size: .75em;
}

.custom-scale {
  flex-direction: row;
  margin-bottom: 1em;
  display: flex;
}

.custom-scale-label {
  flex-grow: unset;
  margin-top: .25em;
  margin-right: 1em;
  font-weight: bold;
  display: flex;
}

[dir="rtl"] .custom-scale-label {
  margin-left: 1em;
  margin-right: 0;
}

.custom-scale-popover {
  margin-top: -.2em;
}

.custom-scale-control {
  flex-grow: 1;
}

.custom-scale-info {
  color: #306c72;
}

.custom-scale-disabled, .custom-scale-disabled .custom-scale-info {
  color: #a6b2c8;
}

.save-as-image-dialog {
  width: 720px;
}

.save-as-image-dialog .checkbox-item {
  margin-left: 20px;
}

.save-as-image-dialog .checkbox-item:first-of-type {
  margin-left: 0;
}

[dir="rtl"] .save-as-image-dialog .checkbox-item {
  margin-left: 0;
  margin-right: 20px;
}

[dir="rtl"] .save-as-image-dialog .checkbox-item:first-of-type {
  margin-right: 0;
}

.save-as-image-dialog footer {
  text-align: center;
}

.save-as-image-dialog footer a {
  white-space: nowrap;
}

.save-as-image-options {
  text-align: center;
  margin-bottom: 1em;
}

.save-as-image-preview {
  max-height: 300px;
  border-top: 3px dashed #cde1ea;
  border-bottom: 3px dashed #cde1ea;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.save-as-image-preview-loading {
  background-color: #ffffffe6;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.save-as-image-preview-image {
  background-image: linear-gradient(45deg, #eee 25%, #0000 25%), linear-gradient(-45deg, #eee 25%, #0000 25%), linear-gradient(45deg, #0000 75%, #eee 75%), linear-gradient(-45deg, #0000 75%, #eee 75%);
  background-position: 0 0, 0 12px, 12px -12px, -12px 0;
  background-size: 24px 24px;
  line-height: 0;
}

.save-as-image-preview-image img {
  max-width: 100%;
  max-height: 300px;
  cursor: move;
  cursor: grab;
  margin: 0 auto;
  display: block;
}

.save-as-image-preview-error {
  height: 200px;
  justify-content: center;
  align-items: center;
  padding: 3em;
  display: flex;
}

.save-as-image-too-large-error {
  color: #dc1414;
  font-weight: bold;
}

.save-as-image-download {
  text-align: center;
}

.save-as-image-download button {
  line-height: inherit;
}

.switch-item {
  display: flex;
}

.switch-root {
  width: 39px;
  height: 21px;
  cursor: pointer;
  --webkit-tap-highlight-color: #0000;
  background-color: #fff;
  border: 0;
  border-radius: 9999px;
  margin-top: -2px;
  padding: 0;
  transition: background-color .12s;
  position: relative;
  box-shadow: 0 0 0 1px #52a2af;
}

.switch-root:focus, .switch-root:active:not(:disabled) {
  box-shadow: 0 0 0 2px #52a2af;
}

.switch-root:disabled {
  box-shadow: 0 0 0 1px #d3d3d3;
}

.switch-root:disabled + label {
  color: #a9a9a9;
}

.switch-root[data-state="checked"] {
  background-color: #e3f5fa;
  box-shadow: 0 0 0 1px #52a2af;
}

.switch-root[data-state="checked"]:focus, .switch-root[data-state="checked"]:active:not(:disabled) {
  box-shadow: 0 0 0 2px #52a2af;
}

.switch-root + label {
  -webkit-user-select: none;
  user-select: none;
  margin-left: .75em;
}

[dir="rtl"] .switch-root + label {
  margin-left: 0;
  margin-right: .75em;
}

.switch-thumb {
  width: 15px;
  height: 15px;
  will-change: transform;
  background-color: #dde2ed;
  border-radius: 9999px;
  transition: transform .1s, background-color .12s;
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  transform: translateX(3px);
}

.switch-thumb[data-state="checked"] {
  background-color: #71b4c4;
  transform: translateX(21px);
}

.switch-thumb[data-disabled] {
  background-color: #0000001a;
}

.radio-group-item {
  margin: .8em 0;
  display: flex;
}

.radio-group-radio {
  width: 21px;
  height: 21px;
  cursor: pointer;
  --webkit-tap-highlight-color: #0000;
  background-color: #fff;
  border: 0;
  border-radius: 9999px;
  margin-top: -2px;
  padding: 0;
  transition: background-color .12s;
  position: relative;
  box-shadow: 0 0 0 1px #52a2af;
}

.radio-group-radio:focus, .radio-group-radio:active:not(:disabled) {
  box-shadow: 0 0 0 2px #52a2af;
}

.radio-group-radio:disabled {
  box-shadow: 0 0 0 1px #d3d3d3;
}

.radio-group-radio:disabled + label {
  color: #a9a9a9;
}

.radio-group-radio + label {
  -webkit-user-select: none;
  user-select: none;
  margin-left: .75em;
}

[dir="rtl"] .radio-group-radio + label {
  margin-left: 0;
  margin-right: .75em;
}

.radio-group-indicator {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.radio-group-indicator:after {
  content: "";
  width: 11px;
  height: 11px;
  background-color: #52a2af;
  border-radius: 50%;
  display: block;
}

.radio-group-sublabel {
  color: gray;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: .8em;
  line-height: 8px;
  display: block;
}

.profile-settings-item {
  margin-top: 1.2em;
}

.profile-settings-item h3 {
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.profile-settings-editable, .profile-settings-editable > form {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.profile-settings-editable > :not(:last-child), .profile-settings-editable > form > :not(:last-child) {
  margin-right: .5em;
}

[dir="rtl"] .profile-settings-editable > :not(:last-child), [dir="rtl"] .profile-settings-editable > form > :not(:last-child) {
  margin-left: .5em;
  margin-right: 0;
}

.profile-settings-editable > p, .profile-settings-editable > form > p {
  height: 2.5rem;
  flex-grow: 1;
  align-items: center;
  margin: 0;
  display: flex;
}

.profile-settings-editable input, .profile-settings-editable > form input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2.5rem;
  border: 1px solid gray;
  border-radius: 4px;
}

.profile-settings-editable .profile-settings-button, .profile-settings-editable > form .profile-settings-button {
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  display: flex;
}

.profile-settings-editable .profile-settings-button button, .profile-settings-editable > form .profile-settings-button button {
  height: 2.5rem;
  align-items: center;
  margin: 0;
  padding: 0 1.5em;
  display: flex;
  position: relative;
}

.profile-settings-editable .profile-settings-button button + button, .profile-settings-editable > form .profile-settings-button button + button {
  margin-left: .5em;
}

[dir="rtl"] .profile-settings-editable .profile-settings-button button + button, [dir="rtl"] .profile-settings-editable > form .profile-settings-button button + button {
  margin-left: 0;
  margin-right: .5em;
}

.profile-settings-pending {
  filter: grayscale();
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 4px;
}

.profile-display-name-not-set {
  color: gray;
}

.profile-display-name-messages p {
  color: red;
  margin: .25em 0 0;
  font-size: .9rem;
}

.settings-dialog {
  width: 650px;
  height: 70vh;
  min-height: 300px;
  max-height: 90vh;
}

.settings-dialog-content {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
}

.settings-dialog-left {
  width: 180px;
  border-right: 1px solid #9adef2;
  overflow-x: hidden;
  overflow-y: auto;
}

[dir="rtl"] .settings-dialog-left {
  border-left: 1px solid #9adef2;
}

.settings-dialog-left ul {
  margin: .5em 0 0;
  padding: 0;
  list-style-type: none;
}

.settings-dialog-left li {
  cursor: pointer;
  padding: .8em 1.2em;
}

.settings-dialog-left li:hover {
  background-color: #dcf3f9;
}

.settings-dialog-right {
  flex: 1;
  padding: 2em;
  overflow-x: hidden;
  overflow-y: auto;
}

.settings-dialog-right h2 {
  color: #306c72;
  font-weight: normal;
}

.settings-dialog-right h2:first-child {
  margin-top: 0;
}

.settings-dialog-right section {
  margin-bottom: 2em;
}

.settings-menu-active {
  color: #154447;
  background-color: #ebf9fc;
  font-weight: bold;
}

.settings-menu-icon {
  vertical-align: text-top;
  color: #306c72;
  margin-right: .5em;
}

[dir="rtl"] .settings-menu-icon {
  margin-left: .5em;
}

.settings-item {
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

.sign-in-dialog {
  width: 450px;
  min-height: 250px;
}

.sign-in-loading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sign-in-email-label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
}

.sign-in-input {
  width: 100%;
  border: 1px solid #89c1ce;
  border-radius: 3px;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  box-shadow: inset 0 1px 1px #00000012;
}

.sign-in-input:focus {
  border-color: #7dbbc9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000012, 0 0 8px #71b4c4e6;
}

.sign-in-input.sign-in-input-error {
  border: 1px solid red !important;
}

.sign-in-email-password-note {
  color: #787878;
  margin-top: .25em;
  margin-bottom: .25em;
  line-height: 1.3;
}

.sign-in-loading-message {
  text-align: center;
  font-size: 1.25em;
  font-weight: normal;
}

.sign-in-social-heading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  font-size: 1.25em;
  font-weight: 700;
  display: flex;
}

.sign-in-social-heading span {
  background-color: #fff;
  padding: 1em;
  display: inline-block;
  position: absolute;
}

.sign-in-button {
  width: 100%;
  position: relative;
  align-items: center !important;
  display: flex !important;
}

.sign-in-social-button {
  text-align: left;
  padding-left: 54px !important;
}

.sign-in-social-button svg, .sign-in-social-button img {
  width: 27px;
  height: 27px;
  position: absolute;
  left: 15px;
}

.sign-in-twitter-button .fa-twitter {
  color: #4a99e9 !important;
}

.sign-in-facebook-button .fa-square-facebook {
  color: #1877f2 !important;
}

.sign-in-disclaimer {
  text-align: center;
  margin-top: 2em;
  margin-left: 5em;
  margin-right: 5em;
}

.sign-in-email-sent {
  text-align: center;
  margin: 3em 2em 2em;
}

.sign-in-email {
  font-weight: 700;
}

.sign-in-resend {
  margin-top: 2em;
}

.sign-in-error-message {
  color: red;
  margin-top: .25em;
  margin-bottom: .25em;
  font-weight: bold;
}

.sign-in-email-button {
  justify-content: center;
}

.whats-new-dialog {
  width: 550px;
  height: 80vh;
  max-height: 800px;
  font-family: Inter, Rubik, sans-serif;
  font-size: 14px;
}

.whats-new-content {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  position: absolute;
  overflow-y: scroll;
}

.whats-new-content h1 {
  display: none;
}

.whats-new-content h2 {
  color: #306c72;
  font-size: 1.75rem;
  font-weight: normal;
}

.whats-new-content h2:not(:first-of-type) {
  border-top: 1px solid #c1ebf7;
  margin-top: 1.25em;
  padding-top: 1.25em;
}

.whats-new-content h3 {
  color: #306c72;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-size: 1.35rem;
  font-weight: normal;
}

.whats-new-content ul {
  padding-left: 1.5rem;
}

.whats-new-content li {
  margin-bottom: .75em;
}

.whats-new-content ul ul {
  margin-top: 1em;
}

.whats-new-content img {
  width: 100%;
}

.whats-new-content li > img {
  margin-top: 1em;
}

.whats-new-content .img-ui {
  border: 1px solid #89c1ce;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 2px 10px #0000000d;
}

.whats-new-loading {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.whats-new-language-banner {
  text-align: center;
  background-color: #fff0c0;
  border-bottom: 5px solid #ffd755;
  padding: .75em 2.5em;
}

.whats-new-scroll-shade {
  width: 100%;
  height: 40px;
  pointer-events: none;
  opacity: 0;
  background: linear-gradient(#52a2af1a 0%, #0000 100%);
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.whats-new-scroll-shade.visible {
  opacity: 1;
}

.newsletter-dialog {
  min-width: 400px;
  max-width: 520px;
}

.newsletter-dialog label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
}

.newsletter-dialog form {
  margin-top: 1.5em;
}

.newsletter-dialog input[type="email"] {
  width: 100%;
  border: 1px solid #89c1ce;
  border-radius: 3px;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  display: block;
  box-shadow: inset 0 1px 1px #00000012;
}

.newsletter-dialog input[type="email"]:focus {
  border-color: #7dbbc9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000012, 0 0 8px #71b4c4e6;
}

.newsletter-dialog input[type="email"].subscribe-input-error {
  border: 1px solid red !important;
}

.subscribe-error {
  color: red;
  margin-top: .25em;
  margin-bottom: .25em;
  font-weight: bold;
}

.subscribe-buttons {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

.upgrade-dialog {
  width: 440px;
  min-height: 220px;
  max-height: 75vh;
  padding: 20px;
}

.upgrade-dialog header {
  text-align: left;
  background-color: #fff;
  padding: 0;
}

[dir="rtl"] .upgrade-dialog header {
  text-align: right;
}

.upgrade-dialog .dialog-content {
  overflow-x: hidden;
  overflow-y: scroll;
}

.upgrade-dialog-content {
  margin-bottom: 2em;
  display: flex;
}

.sentiment-survey-about-dialog {
  max-width: 500px;
}

.dialog-error {
  max-width: 400px;
}

.phase-rename-dialog {
  min-width: 400px;
  max-width: 520px;
}

.phase-rename-dialog label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
}

.phase-rename-dialog form {
  margin-top: 1.5em;
}

.phase-rename-dialog input {
  width: 100%;
  border: 1px solid #89c1ce;
  border-radius: 3px;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  display: block;
  box-shadow: inset 0 1px 1px #00000012;
}

.phase-rename-dialog input:focus {
  border-color: #7dbbc9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000012, 0 0 8px #71b4c4e6;
}

.phase-import-dialog {
  min-width: 400px;
  max-width: 520px;
}

.phase-import-dialog label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
}

.phase-import-dialog form {
  margin-top: 1.5em;
}

.phase-import-dialog input {
  width: 100%;
  border: 1px solid #89c1ce;
  border-radius: 3px;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  display: block;
  box-shadow: inset 0 1px 1px #00000012;
}

.phase-import-dialog input:focus {
  border-color: #7dbbc9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000012, 0 0 8px #71b4c4e6;
}

.layout-edit-dialog {
  min-width: 400px;
  max-width: 520px;
}

.layout-edit-dialog label {
  margin-bottom: .25em;
  margin-left: 1rem;
  font-size: 1.2em;
  font-weight: 700;
  display: inline;
}

.layout-edit-dialog form {
  margin-top: 1.5em;
}

.layout-edit-dialog input#layout-new-name {
  width: 100%;
  border: 1px solid #89c1ce;
  border-radius: 3px;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  display: block;
  box-shadow: inset 0 1px 1px #00000012;
}

.layout-edit-dialog input#layout-new-name:focus {
  border-color: #7dbbc9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000012, 0 0 8px #71b4c4e6;
}

.phase-paste-dialog {
  min-width: 400px;
  max-width: 520px;
}

.phase-paste-dialog label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
}

.phase-paste-dialog input {
  width: 100%;
  border: 1px solid #89c1ce;
  border-radius: 3px;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  display: block;
  box-shadow: inset 0 1px 1px #00000012;
}

.phase-paste-dialog input:focus {
  border-color: #7dbbc9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000012, 0 0 8px #71b4c4e6;
}

.environment-selector {
  height: auto;
  grid-gap: .75em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  padding: 1em;
  display: grid;
}

.environment-item {
  width: 40px;
  height: 40px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 6px;
  transition: box-shadow .12s;
  position: relative;
}

.environment-item:focus {
  box-shadow: 0 0 0 2px #52a2af, inset 0 0 0 2px #fff;
}

.environment-item.environment-active {
  box-shadow: 0 0 0 2px #b56d48, inset 0 0 0 2px #fff;
}

.environment-item.environment-disabled {
  cursor: default;
}

.environment-item.environment-disabled .environment-disabled-overlay {
  width: 100%;
  height: 100%;
  background-color: #dddddda6;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.environment-item.environment-disabled .fa-lock {
  z-index: 2;
  color: #894528;
  position: absolute;
  top: 4px;
  right: 4px;
}

[dir="rtl"] .environment-item.environment-disabled .fa-lock {
  left: 4px;
  right: auto;
}

.environment-editor-container-outer {
  z-index: 750;
  max-width: 216px;
  position: absolute;
  bottom: 90px;
  left: 50px;
}

.environment-editor-container-inner {
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 10px #0000001a;
}

[dir="rtl"] .environment-editor-container-inner {
  left: auto;
  right: 50px;
}

.environment-editor header {
  color: #154447;
  background-color: #dcf3f9;
  align-items: center;
  padding: .5em;
  display: flex;
}

.environment-editor.react-draggable header {
  cursor: move;
  cursor: grab;
}

.environment-editor.react-draggable-dragging header {
  cursor: grabbing;
}

.environment-editor header img {
  height: 16px;
  width: auto;
  pointer-events: none;
  margin-left: .5em;
}

[dir="rtl"] .environment-editor header img {
  margin-left: auto;
  margin-right: .5em;
}

.environment-editor header h3 {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  margin: 0 .25em;
  font-size: 1em;
  overflow: hidden;
}

.environment-editor header h3 .octicon {
  margin-right: .5em;
}

[dir="rtl"] .environment-editor header h3 .octicon {
  margin-left: .5em;
  margin-right: auto;
}

.environment-editor-content {
  padding: 0;
}

.environment-upgrade-box {
  text-align: center;
  color: #562211;
  background-color: #fff2c9;
  padding: .75em 1em;
  font-size: 1rem;
  line-height: 1.2;
}

.environment-upgrade-box button {
  width: auto;
  color: #894528;
  background-color: #0000;
  border: 1px solid #d88d64;
  border-radius: 4px;
  margin: .75em auto .25em;
  padding: .5em 1em;
  font-weight: bold;
  display: block !important;
}

.environment-upgrade-box button:disabled {
  border: 1px solid #e8dad1;
}

.environment-upgrade-box button:hover {
  color: #562211;
  background-color: #0000;
}

.environment-editor .close {
  width: 24px;
  height: 24px;
  justify-content: center;
  padding: 0;
  position: relative;
  top: auto;
  right: auto;
  align-items: center !important;
  display: flex !important;
}

.gallery-street-item {
  width: 180px;
  height: 110px;
  box-sizing: content-box;
  border-radius: 4px;
  padding: 5px;
  display: inline-block;
  position: relative;
}

.gallery-street-item > * {
  box-sizing: border-box;
}

.gallery-street-item:first-child {
  padding-left: 2px;
}

.gallery-street-item:last-child {
  padding-right: 2px;
}

.gallery-street-item > a {
  color: #000;
  text-align: center;
  white-space: normal;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: #a9ccdbcc;
  border-radius: 4px;
  text-decoration: none;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.gallery-street-item > a canvas {
  width: 180px;
  height: 110px;
  opacity: .45;
  filter: grayscale(.75);
  border-radius: 4px;
  transition: opacity 45ms, filter 45ms;
  position: absolute;
  top: 2px;
  left: 0;
  transform: scale(1.35);
}

.gallery-street-item > a:hover canvas {
  opacity: 1;
  filter: grayscale(0);
}

.gallery-with-owner.gallery-street-item > a canvas {
  top: -10px;
}

.gallery-street-item.gallery-selected > a {
  background: #a9ccdb;
  box-shadow: 0 0 0 2px #b56d48, inset 0 0 0 2px #fff;
}

.gallery-street-item.gallery-selected > a .gallery-street-item-canvas > div {
  transform: scale(1.05);
}

.gallery-street-item.gallery-selected > a canvas {
  opacity: 1;
  filter: grayscale(0);
}

.gallery-street-item-canvas {
  border-radius: 2px;
  position: absolute;
  inset: 2px;
  overflow: hidden;
}

.gallery-street-item-canvas > div {
  width: 100%;
  height: 100%;
  transform-origin: 50% 80px;
  transition: transform 45ms ease-in-out;
  position: relative;
}

.gallery-street-item-canvas > div:hover {
  transform: scale(1.05);
}

.gallery-street-item-canvas .gallery-street-item-ground {
  width: 100%;
  height: 22px;
  background-color: #f0f0f0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.gallery-with-owner .gallery-street-item-canvas .gallery-street-item-ground {
  height: 32px;
}

.gallery-street-item-creator {
  text-align: center;
  color: #000;
  font-size: .8em;
  display: block;
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
}

.gallery-street-item-date {
  text-align: center;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  font-size: .8em;
  font-weight: 300;
  display: block;
  position: absolute;
  bottom: 5px;
  left: 20px;
  right: 20px;
  overflow: hidden;
}

.gallery-street-item-delete {
  width: 20px;
  height: 20px;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  padding: 0;
  font-size: .65em;
  line-height: 25px;
  position: absolute;
  bottom: 7px;
  right: 7px;
  align-items: center !important;
  display: flex !important;
}

[dir="rtl"] .gallery-street-item-delete {
  left: 7px;
  right: auto;
}

.gallery-street-item-delete:hover {
  background-color: #ffffff80;
}

.gallery-street-item-delete:active {
  background-color: #ffffffbf;
}

.gallery-street-item-delete svg {
  width: 12px;
  color: #52a2af !important;
}

.gallery-street-item-delete:hover svg {
  color: #306c72 !important;
}

.gallery-street-item-error {
  width: 100%;
  height: 100%;
  color: #306c72;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-header {
  flex-direction: row;
  flex: 0 0 40px;
  align-items: center;
  display: flex;
}

.gallery-header .avatar {
  height: 32px;
  flex: 0 0 32px;
  margin-right: .5em;
}

[dir="rtl"] .gallery-header .avatar {
  margin-left: .5em;
  margin-right: 0;
}

.gallery-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  font-size: 1.5em;
  overflow: hidden;
}

.gallery-street-count {
  color: gray;
  white-space: nowrap;
  font-size: 1.5em;
}

.gallery-streets-container {
  flex-direction: row;
  display: flex;
}

.streets-scrollable-container {
  height: 180px;
  min-width: 0;
  flex-grow: 1;
  position: relative;
  top: 1px;
}

.streets-scrollable-container button.scrollable {
  width: 30px;
  margin-top: 45px;
}

.streets-scrollable-container button.scrollable.scroll-left {
  left: -10px !important;
}

.streets-scrollable-container button.scrollable.scroll-right {
  right: -10px !important;
}

.streets-scrollable-container .streets {
  white-space: nowrap;
  height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: scroll;
}

.gallery-user-buttons {
  height: 110px;
  flex-direction: column;
  flex: 0 0 100px;
  margin-top: 6px;
  margin-right: 16px;
  display: flex;
}

.gallery-user-buttons a.btn, .gallery-user-buttons button {
  justify-content: center;
  padding-left: .5em;
  padding-right: .5em;
  align-items: center !important;
  display: flex !important;
}

[dir="rtl"] .gallery-user-buttons {
  margin-left: 16px;
  margin-right: 0;
}

.gallery-new-street {
  flex-grow: 1;
  font-weight: bold;
}

.gallery-copy-last-street {
  height: 40px;
  flex-basis: 40px;
  margin-top: 10px;
}

.gallery-shield {
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 800;
  position: absolute;
  top: 0;
  left: 0;
}

body.gallery-visible .main-screen {
  transform: translateY(90px);
}

body.gallery-visible .street-nameplate-container {
  transform: translateY(60px);
}

body:not(.safari).gallery-visible .street-section-sky {
  transform: translateY(-20px);
}

.gallery-panel {
  height: 180px;
  z-index: 900;
  pointer-events: none;
  transform-origin: 50% 0;
  background: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  flex-direction: column;
  padding: 10px 16px 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  overflow: hidden;
  transform: translateY(-180px)rotateX(25deg);
  box-shadow: 0 0 10px #0000001a;
}

.gallery-loading, .gallery-error {
  text-align: center;
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.gallery-loading a, .gallery-error a {
  color: inherit;
}

body:not(.gallery-no-move-transition) .main-screen, body:not(.gallery-no-move-transition) .gallery-panel, body:not(.gallery-no-move-transition) .street-nameplate-container, body:not(.gallery-no-move-transition) .street-section-sky {
  transition: transform .8s;
}

body.gallery-visible .gallery-panel {
  pointer-events: auto;
  transform: translateY(0);
}

.segment-image {
  pointer-events: none;
  position: absolute;
  top: 150px;
  left: 0;
  overflow: hidden;
}

body:not(.segment-resize-dragging) .segment.outside .segment-image {
  transition: none;
}

.segment-drag-layer {
  pointer-events: none;
  z-index: 400;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.segment.floating {
  opacity: .75;
  z-index: 200;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: none !important;
}

.segment.floating.first-drag-move {
  transition: top .15s;
}

.toast {
  width: auto;
  height: auto;
  pointer-events: auto;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #0000001a;
}

.toast-content {
  max-width: 330px;
  min-width: 200px;
  background-color: #fff0c0;
  border-top: 5px solid #ffd755;
  padding: 1em 1.25em;
  font-size: 13px;
  line-height: 1.4;
  display: inline-block;
  position: relative;
}

.toast-body {
  padding-right: 20px;
}

[dir="rtl"] .toast-body {
  padding-left: 20px;
  padding-right: 0;
}

.toast-message {
  margin: 0;
}

.toast-action {
  margin-top: 1em;
}

.toast-action button {
  width: 100%;
  min-height: 32px;
  color: #894528;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #d88d64;
  border-radius: 4px;
  margin: 0;
  padding: .25em .5em;
  font-weight: bold;
  display: block;
}

.toast-action button:hover {
  color: #562211;
  background-color: #0000;
}

.toast .close {
  width: 24px;
  height: 24px;
  color: #b56d48;
  justify-content: center;
  padding: 0;
  top: 6px;
  right: 6px;
  align-items: center !important;
  display: flex !important;
}

[dir="rtl"] .toast .close {
  left: 6px;
  right: auto;
}

.toast .close:hover {
  color: #894528;
  background-color: #ffffff80;
}

.toast .close:active {
  background-color: #ffffffbf;
}

.toast h3 {
  margin: 0 0 .25em;
}

.toast-success .toast-content {
  background-color: #c8edd1;
  border-top-color: #93dba9;
}

.toast-success h3 {
  color: #093f14;
}

.toast-success .close {
  color: #1a914e;
}

.toast-success .close:hover {
  color: #1f7234;
}

.toast-success .toast-action button {
  color: #1f7234;
  border-color: #1a914e;
}

.toast-success .toast-action button:hover {
  color: #093f14;
}

.toast-warning .toast-content {
  background-color: #fff9f9;
  border-top-color: #ff5b5b;
}

.toast-warning h3 {
  color: #a00101;
}

.toast-warning .close {
  color: #c10000;
}

.toast-warning .close:hover {
  color: #a00101;
}

.toast-warning .toast-action button {
  color: #c10000;
  border-color: #c10000;
}

.toast-warning .toast-action button:hover {
  color: #a00101;
}

.toast-web-monetization img.wm-icon {
  width: 36px;
  height: 36px;
  margin-right: .75em;
}

[dir="rtl"] .toast-web-monetization img.wm-icon {
  margin-left: .75em;
  margin-right: 0;
}

.toast-web-monetization a, .toast-web-monetization a:visited {
  color: #1f7234;
}

.toast-web-monetization a:hover, .toast-web-monetization a:active {
  color: #1a914e;
}

.toast-icon-header {
  align-items: center;
  display: flex;
}

.toast-icon-header h3 {
  margin: 0;
  line-height: 1.2;
}

.toast-container {
  min-width: 300px;
  max-width: 330px;
  z-index: 700;
  pointer-events: none;
  flex-direction: column;
  align-items: flex-end;
  line-height: 0;
  display: flex;
  position: absolute;
  top: 50px;
  right: 30px;
}

.toast-container > div {
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

[dir="rtl"] .toast-container {
  left: 30px;
  right: auto;
}

.debug-info {
  z-index: 900;
  background-color: #c0c0c080;
  padding: 30px;
  position: fixed;
  inset: 0;
}

.debug-info > textarea {
  width: 100%;
  height: 100%;
  resize: none;
  background-color: #f5f5f5;
  border: 1px solid #d3d3d3;
  font-family: monospace;
  box-shadow: 0 0 0 10px #fff;
}

.blocking-shield {
  width: 100%;
  height: 100%;
  z-index: 900;
  color: #0000;
  cursor: wait;
  background: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 1.2rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.blocking-shield button {
  cursor: pointer;
}

.blocking-shield.visible {
  display: flex;
}

.blocking-shield.darken, .blocking-shield.darken-immediately {
  color: #000;
  background: #a9ccdbd9;
}

.blocking-shield.darken {
  transition: background 1.5s, color 1.5s;
}

.blocking-shield.darken-immediately {
  transition: none;
}

.blocking-shield .message {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 250%;
  font-weight: 300;
}

.resize-guides {
  z-index: 700;
  pointer-events: none;
  position: absolute;
  top: -75px;
  bottom: 120px;
  left: 50%;
}

.resize-guide {
  height: 100%;
  border-left: 1px dashed #0006;
  border-right: 1px dashed #0006;
  position: absolute;
}

body.dark-environs-invert-ui .resize-guide {
  border-left: 1px dashed #fff6;
  border-right: 1px dashed #fff6;
}

.resize-guide-max-before, .resize-guide-max-after, .resize-guide-min-before, .resize-guide-min-after {
  width: 50px;
  color: #0009;
  font-size: .8rem;
  position: absolute;
  bottom: 400px;
}

body.dark-environs-invert-ui .resize-guide-max-before, body.dark-environs-invert-ui .resize-guide-max-after, body.dark-environs-invert-ui .resize-guide-min-before, body.dark-environs-invert-ui .resize-guide-min-after {
  color: #fff9;
}

.resize-guide-max-before {
  text-align: right;
  left: -55px;
}

.resize-guide-max-after {
  text-align: left;
  right: -55px;
}

.resize-guide-min-before {
  text-align: left;
  left: 5px;
}

.resize-guide-min-after {
  text-align: right;
  right: 5px;
}

.segment-label-container {
  height: 90px;
  border-left: 1px solid #a0a0a0;
  border-right: 1px solid #a0a0a0;
  justify-content: center;
  font-size: .9em;
  display: flex;
  position: absolute;
  top: 595px;
  left: 0;
  right: -1px;
}

.segment-label {
  color: #28231e;
  pointer-events: none;
  font-weight: 300;
  line-height: 1.4;
  position: absolute;
  top: 44px;
  left: 2px;
  right: 2px;
}

.segment-label > p {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  overflow: hidden;
}

.segment.hover .segment-label {
  z-index: 1;
  background-color: #d8d3cb;
  margin-top: -4px;
  padding: 4px;
  font-weight: 700;
  left: auto;
  right: auto;
  overflow: visible;
}

.segment.warning .segment-label {
  color: #dc1414;
}

.segment-width {
  height: 24px;
  color: #28231e;
  pointer-events: none;
  font-weight: 300;
  line-height: 20px;
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  overflow: hidden;
}

:root[dir="rtl"] .segment-width {
  direction: rtl !important;
}

.segment-grid {
  height: 10px;
  pointer-events: none;
  background-image: linear-gradient(90deg, #a0a0a0 1px, #0000 1px);
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
}

.segment-grid.units-imperial {
  background-size: 12px 100%;
}

.segment-grid.units-metric {
  background-size: 20px 100%;
}

.segment-empty {
  pointer-events: none;
  z-index: -1;
  position: absolute;
}

.segment-empty .segment-label, .segment-empty .segment-width {
  color: #28231e80;
}

.street-scroll-indicators {
  position: absolute;
  bottom: 0;
}

.street-scroll-indicators button {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  height: 80px;
  opacity: .25;
  letter-spacing: -.15em;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
  background-color: #0000;
  border: 0;
  margin-top: -80px;
  padding: 0 .2em;
  font-size: 80px;
  font-weight: normal;
  line-height: 1;
  position: fixed;
}

.street-scroll-indicators button:hover {
  opacity: .45;
}

.street-scroll-indicators button:active {
  opacity: .65;
}

[dir="rtl"] .street-scroll-indicators button {
  direction: ltr;
}

.street-scroll-indicator-left {
  left: 0;
}

.street-scroll-indicator-right {
  right: 0;
  padding-right: .4em !important;
}

.sky-background {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0;
}

.sky-background-color {
  width: 100%;
  height: 100%;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.sky-background-objects {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0;
}

.sky-background-objects > div {
  opacity: 0;
  transition: opacity .5s, transform .5s;
  transform: translateY(-14px);
}

.sky-background-object-enter-done {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.street-section-sky {
  pointer-events: none;
  opacity: 1;
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
}

.rear-clouds, .front-clouds {
  width: 100%;
  background-position: 0 0;
  background-repeat: repeat-x;
  transition: opacity .5s;
  position: absolute;
  left: 0;
}

.rear-clouds {
  height: 250px;
  background-image: url("sky-rear.5c350576.svg");
  background-size: 250px 250px;
  bottom: 205px;
}

.front-clouds {
  height: 280px;
  background-image: url("sky-front.8927059c.svg");
  background-size: 250px 280px;
  bottom: 55px;
}

.environment-animations .rear-clouds {
  animation-name: rear-clouds-move;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.environment-animations .front-clouds {
  animation-name: front-clouds-move;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rear-clouds-move {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 250px 0;
  }
}

@keyframes front-clouds-move {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 250px 0;
  }
}

.sky-foreground {
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.street-section-dirt {
  z-index: -2;
  pointer-events: none;
  background: #d8d3cb;
  border-top: 5px solid #352d27;
  position: absolute;
  top: 440px;
  left: 0;
  right: 0;
}

.street-section-dirt-left, .street-section-dirt-right {
  content: " ";
  width: 360px;
  height: 40px;
  background-color: #352d27;
  position: absolute;
  top: -45px;
}

.street-section-dirt-left {
  left: 0;
}

.street-section-dirt-right {
  right: 0;
}

body.segment-resize-dragging, body.segment-resize-dragging * {
  cursor: col-resize !important;
}

.drag-handle {
  width: 30px;
  height: 100px;
  color: #52a2af;
  z-index: 600;
  pointer-events: none;
  cursor: col-resize;
  opacity: 0;
  background: #fff;
  border-top: 5px solid #dcf3f9;
  border-bottom: 5px solid #dcf3f9;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: transform .15s, opacity .15s;
  display: flex;
  position: absolute;
  top: 320px;
}

.drag-handle:hover {
  color: #306c72;
}

.drag-handle-left {
  text-align: left;
  text-indent: -5px;
  transform-origin: 100%;
  left: -5px;
  transform: rotateY(90deg);
}

.drag-handle-right {
  text-align: right;
  text-indent: -3px;
  transform-origin: 0;
  right: -5px;
  transform: rotateY(-90deg);
}

.segment.immediate-show-drag-handles .drag-handle {
  transition: none !important;
}

.drag-handle.floating {
  background-color: #52a2af;
}

body.segment-resize-dragging .drag-handle.floating, .segment.show-drag-handles .drag-handle {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.segment {
  height: 595px;
  text-align: center;
  perspective: 500px;
  touch-action: none;
  display: block;
  position: absolute;
  top: -150px;
}

.segment-canvas-container {
  width: 100%;
  height: 100%;
}

body.segment-move-dragging .segment-empty {
  display: none;
}

body:not(.segment-resize-dragging) .segment {
  transition: width .15s, left .15s, transform .15s;
}

body.immediate-segment-resize .segment, .segment.create {
  transition: none !important;
}

.segment.hover {
  z-index: 200 !important;
}

.segment.hover .hover-bk {
  z-index: -1;
  height: 585px;
  pointer-events: none;
  background: #fff3;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.segment.hover.hover-layout .hover-bk {
  height: 415px;
  top: 170px;
}

body:not(.read-only) .segment:not(.layout-segment) {
  cursor: grab;
}

.segment.dragged-out {
  opacity: 0;
}

body.segment-move-dragging, body.segment-move-dragging * {
  cursor: move !important;
  cursor: grabbing !important;
}

body.not-within-canvas, body.not-within-canvas * {
  cursor: no-drop !important;
}

body.segment-move-dragging #street-section-editable .segment {
  height: 685px;
  transition: left .1s, transform .3s;
}

.switching-away-exit {
  z-index: 1000;
  pointer-events: none;
  perspective: 400px;
  position: absolute;
  transform: none !important;
}

.switching-away-exit-done {
  z-index: 1000 !important;
}

.switching-away-exit-active canvas {
  transform-origin: 50% 120%;
  opacity: 0;
  transform: rotateX(-60deg);
  transition: transform .25s ease-in, opacity .25s ease-in !important;
}

.switching-in-enter {
  perspective: 400px;
  z-index: -1 !important;
}

.switching-in-enter canvas {
  transform-origin: 50% 100%;
  opacity: 0;
  transform: rotateX(90deg);
}

.switching-in-enter-done {
  z-index: -1 !important;
}

.switching-in-enter-active canvas {
  opacity: 1;
  transform: none;
  transition: transform .25s, opacity .25s !important;
}

#street-section-outer {
  touch-action: pan-x;
  overscroll-behavior-x: contain;
  display: block;
  position: absolute;
  inset: -180px 0 -20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

[dir="rtl"] #street-section-outer {
  direction: ltr;
}

.layout-section-outer {
  min-height: 35vh;
  touch-action: pan-x;
  margin-inline: auto;
  overscroll-behavior-x: contain;
  margin-bottom: 8rem;
  inset: -180px 0 -20px;
  overflow-x: auto;
  overflow-y: hidden;
}

[dir="rtl"] .layout-section-outer {
  direction: ltr;
}

#street-section-inner {
  height: 525px;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#street-section-editable, .street-section-editable {
  height: 560px;
  text-align: left;
  touch-action: pan-x;
  position: absolute;
}

.street-section-layout-phase-name {
  z-index: 1;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  top: 25px;
}

.street-section-layout-phase-meta {
  z-index: 1;
  text-align: center;
  font-size: 1rem;
  position: relative;
}

#street-section-canvas {
  z-index: 100;
  height: 520px;
  margin-left: 360px;
  margin-right: 360px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.street-section-building {
  width: 396px;
  height: 550px;
  touch-action: pan-x;
  display: block;
  position: absolute;
  top: -105px;
}

.street-section-building .hover-bk {
  z-index: -1;
  position: absolute;
  inset: -1000px 0 45px;
}

.street-section-building.hover .hover-bk {
  background: #fff3;
}

.street-section-building canvas {
  position: absolute;
  bottom: 0;
}

.street-section-building-left canvas {
  left: 0;
}

.street-segment-building-right canvas {
  right: 0;
}

.print-container {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.print-container img {
  max-width: 100%;
  max-height: 100%;
}

@media print {
  .print-container {
    display: flex !important;
  }

  body > :not(#react-app), #react-app > :not(.print-container) {
    display: none;
  }
}

body.windows .street-name-text {
  line-height: 38px;
}

.street-name {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background: #fff;
  padding: 5px;
  display: inline-block;
}

.street-name-text {
  height: 75px;
  letter-spacing: -.025em;
  text-align: left;
  text-overflow: ellipsis;
  border: 5px solid #000;
  padding: 17px 30px 8px;
  font-family: Overpass, Roadgeek, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 42px;
  overflow: hidden;
}

.street-name-text [dir="rtl"] {
  font-size: 45px;
}

.gallery .streets .street-name {
  max-width: 95%;
  padding: 2px;
  position: relative;
  top: -7px;
}

.gallery .streets .street-name > div, .gallery .streets .street-name > span {
  height: 25px;
  letter-spacing: -.025em;
  border: 2px solid #000;
  padding: 6px 10px 3px;
  font-size: 16px;
  line-height: 14px;
}

.gallery .streets .street-name > div [dir="rtl"], .gallery .streets .street-name > span [dir="rtl"] {
  font-size: 15px;
}

body.segment-move-dragging .street-nameplate-container .street-name {
  pointer-events: none;
}

.street-nameplate-container .street-name {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  max-width: 100%;
  pointer-events: auto;
  position: relative;
}

.street-name-hover-prompt {
  color: #000;
  cursor: pointer;
  white-space: normal;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
  position: absolute;
  inset: 0;
}

.welcome-panel-container {
  z-index: 500;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.welcome-panel {
  text-align: center;
  color: #404040;
  pointer-events: auto;
  background: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0;
  padding: .5em 1.5em .75em;
  font-size: 1.2rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 10px #0000001a;
}

.welcome-panel-content h1 {
  margin-top: .25em;
  margin-bottom: .25em;
  font-size: 250%;
  font-weight: 300;
}

.welcome-panel-content button {
  margin: 0 .25em;
  padding: .5em 1em;
}

.welcome-panel-content ul, .welcome-panel-content p, .welcome-panel-content .paragraph {
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.welcome-panel-content p, .welcome-panel-content .paragraph {
  max-width: 700px;
  line-height: 1.5;
  display: block;
}

.welcome-panel-content li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.welcome-panel-content li:not(:first-child):before {
  content: "·";
  color: #4589a6;
  margin: 0 5px;
  padding-left: 0;
}

.welcome-panel-content input[type="radio"] {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: none;
}

.welcome-panel-content label {
  cursor: pointer;
}

.welcome-panel-content label:hover {
  color: #000;
}

.welcome-panel-content input[type="radio"]:checked + label {
  color: #000;
  font-weight: bold;
}

.welcome-panel-content input[type="radio"]:not(:checked) + label {
  text-decoration: underline;
}

.welcome-panel .street-name {
  max-width: 100%;
  margin: 0 .25em;
  padding: 2px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.welcome-panel .street-name > div, .welcome-panel .street-name > span {
  height: 25px;
  letter-spacing: -.025em;
  border: 2px solid #000;
  padding: 6px 10px 3px;
  font-size: 16px;
  line-height: 14px;
}

.welcome-panel .street-name > div [dir="rtl"], .welcome-panel .street-name > span [dir="rtl"] {
  font-size: 15px;
}

.notification-bar {
  z-index: 800;
  text-align: center;
  background-color: #fff0c0;
  border-bottom: 5px solid #ffd755;
  padding: .75em calc(36px + 1.5em);
  position: relative;
}

.notification-bar .close {
  width: 24px;
  height: 24px;
  justify-content: center;
  padding: 0;
  top: 7px;
  right: 6px;
  align-items: center !important;
  display: flex !important;
}

.notification-bar .close:hover {
  background-color: #ffffff80;
}

.notification-bar .close:active {
  background-color: #ffffffbf;
}

.notification-bar .close svg {
  color: #b56d48 !important;
}

.notification-bar .close:hover svg {
  color: #894528 !important;
}

.layout-view {
  height: 100vh;
  width: 100vw;
  background-color: #a9ccdb;
  flex-direction: column;
  gap: 8rem;
  padding-top: 120px;
  display: flex;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.layout-view::-webkit-scrollbar {
  width: 10px;
}

.layout-view::-webkit-scrollbar-thumb {
  background-color: #5bc17d;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

#layout-view-layouts-menu, #layout-view-phases-menu {
  max-width: 250px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  list-style-type: none;
  display: flex;
  position: fixed;
  top: 150px;
  left: 25px;
}

#layout-view-layouts-menu h1, #layout-view-phases-menu h1 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}

@media screen and (max-width: 1450px) {
  #layout-view-layouts-menu, #layout-view-phases-menu {
    display: none;
  }
}

#layout-view-layouts-menu-header {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

#layout-view-phases-menu {
  left: unset;
  right: 25px;
}

#layout-view-add-phase-button {
  z-index: 1;
  position: relative;
}

/*# sourceMappingURL=main.css.map */
